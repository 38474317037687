<template>
    <el-date-picker
        class="w-100"
        popper-class="ap-code-date-picker"
        placeholder="Add meg a vásárlás dátumát"
        format="YYYY.MM.DD"
        value-format="YYYY-MM-DD"
        :disabled-date="disabledDueDate"
        :modelValue="modelValue"
        :editable="false"
        :clearable="false"
        :teleported="false"
        v-bind="$attrs"
        :shortcuts="[]"
        @change="onChange($event)"
        @focus="onFocus($event)"
    ></el-date-picker>
</template>

<script>
//import moment from "moment";
import { ElDatePicker } from "element-plus";

export default {
    components: {
        ElDatePicker,
    },
    props: {
        campaignStartDate: String,
        campaignEndDate: String,
        modelValue: String,
    },
    data() {
        return {
            currentMonth: new Date(),
            prevMonthButton: null,
            nextMonthButton: null,
            startDate: null,
            endDate: null,
        };
    },
    mounted() {
        this.startDate = this.campaignStartDate?.replace(/-/g, "/");
        this.endDate = this.campaignEndDate?.replace(/-/g, "/");
    },
    methods: {
        disabledDueDate(date) {
            return !(
                date >= new Date(this.startDate) &&
                date <= new Date(this.endDate) &&
                date <= new Date()
            );
            // return !(date >= this.campaignStartDate && date <= new Date());
        },
        onChange(value) {
            this.$emit("update:modelValue", value);
        },
        /**
         * onFocus set datepicker popper
         */
        onFocus(event) {
            this.$nextTick((_) => {
                const popperElm = document.querySelector(".el-popper");
                let prevYearButton;
                let nextYearButton;
                let headerLabelButton;

                prevYearButton = popperElm.querySelector(
                    ".el-date-picker__prev-btn"
                );
                nextYearButton = popperElm.querySelector(
                    ".el-date-picker__next-btn"
                );
                headerLabelButton = popperElm.querySelectorAll(
                    ".el-date-picker__header-label"
                );

                Array.from(headerLabelButton).forEach((el) => {
                    el.style.pointerEvents = "none";
                });

                prevYearButton.classList.add("hide");
                nextYearButton.classList.add("hide");

                this.prevMonthButton = popperElm.querySelector(
                    ".el-date-picker__prev-btn"
                );
                this.nextMonthButton = popperElm.querySelector(
                    ".el-date-picker__next-btn"
                );

                //Draw arrows
                this.drawArrow(this.prevMonthButton, this.nextMonthButton);

                this.prevMonthButton.onclick = (e) => {
                    this.currentMonth.setMonth(
                        this.currentMonth.getMonth() - 1
                    );

                    this.drawArrow(this.prevMonthButton, this.nextMonthButton);
                };

                this.nextMonthButton.onclick = (e) => {
                    this.currentMonth.setMonth(
                        this.currentMonth.getMonth() + 1
                    );

                    this.drawArrow(this.prevMonthButton, this.nextMonthButton);
                };
            });

            this.$emit("focus", "date");
        },
        /**
         * drawArrow is a helper function to datepicker
         */
        drawArrow(arrowLeft, arrowRight) {
            arrowLeft.classList.add("hide");
            arrowRight.classList.add("hide");

            if (
                (this.currentMonth > new Date(this.startDate) &&
                    this.currentMonth.getYear() !=
                        new Date(this.startDate).getYear()) ||
                (this.currentMonth > new Date(this.startDate) &&
                    this.currentMonth.getYear() ==
                        new Date(this.startDate).getYear() &&
                    this.currentMonth.getMonth() !=
                        new Date(this.startDate).getMonth())
            ) {
                arrowLeft.classList.remove("hide");
            }

            if (
                (this.currentMonth < new Date(this.endDate) &&
                    this.currentMonth.getYear() !=
                        new Date(this.endDate).getYear()) ||
                (this.currentMonth < new Date(this.endDate) &&
                    this.currentMonth.getYear() ==
                        new Date(this.endDate).getYear() &&
                    this.currentMonth.getMonth() != new Date().getMonth())
            ) {
                arrowRight.classList.remove("hide");
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
