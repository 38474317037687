<template>
    <section id="user-consenting">
        <div class="py-10" :style="backgroundStyle">
            <div class="container">
                <div class="col-12 col-md-10 ms-auto me-auto">
                    <!--<h2 class="text-center mb-5" v-html="section?.sectionTitle"></h2>
                    <div class="text-center mb-5">
                        <button class="el-button submit-btn" @click="redirectUserToLoginAndConsent">Continue to accept
                            terms</button>
                    </div>-->

                    <iframe id="loginFrame" :style="{ height: `${loginCustomization.height}px` }"
                        :src="authorizationUrl" class="w-100 login-iframe" frameBorder="0"
                        allowtransparency="true"></iframe>

                    <!--<div class="text-center mb-5">
                        <small class="">Just 1 more step to upload your code</small>
                    </div>-->
                </div>
            </div>
        </div>

        <!-- Prizific page builder tool -->
        <client-only>
            <PrizificPageBuilderTool v-if="editable" :section="section" />
        </client-only>
        <!-- Prizific page builder tool end -->
    </section>
</template>

<script setup lang="ts">
import { getStyle } from "../../composables/styleHelper";
import { useAuthStore } from "@/store/user/auth";
import { EHiveLoginText } from "@/enums/PrizificPageBuilder/EHiveLoginText";
import { IComponent } from "~~/interfaces/PrizificPageBuilder/IComponent";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { ISection } from "~~/interfaces/PrizificPageBuilder/ISection";
import { ELoginFormItem } from "~~/enums/PrizificPageBuilder/ELoginFormItem";
import { ERegistrationFormItem } from "~~/enums/PrizificPageBuilder/ERegistrationFormItem";
import { useCampaignDataStore } from "@/store/campaign/campaignData";

interface Props {
    section: IComponent<EComponents> & ISection;
    editable: boolean;
}
const authorizationUrl = ref("");

const authStore = useAuthStore();
const campaignDataStore = useCampaignDataStore();

const loginCustomization = reactive({
    guestTitle: "-",
    guestDescription: "-",
    loggedInTitle: "-",
    loggedInDescription: "-",
    height: "400",
});

async function iframeConsentEventListener(event: any) {
    if (event.data == "auth-state-changed") {
        await authStore.getMe();
    }

    if (
        typeof event.data === "string" &&
        event.data.startsWith("consent-height-changes:")
    ) {
        loginCustomization.height = event.data.replace(
            "consent-height-changes:",
            ""
        );
    }
}

onMounted(async () => {
    makeUrlHiveAuthUrl();

    // In parent app
    // Bind a message event listener to window object
    window.addEventListener("message", iframeConsentEventListener);
});

onUnmounted(async () => {
    window.removeEventListener("message", iframeConsentEventListener);
});

async function redirectUserToLoginAndConsent() {
    window.location.replace(authorizationUrl.value);
}

/**
 * Define props
 */
const props = defineProps<Props>();

// Computed section, form and form items style
const backgroundStyle = computed(() => {
    return getStyle(props.section.style, "userConsentingBackground");
});
const sectionTitleStyle = computed(() => {
    return getStyle(props.section.style, "userConsentingTitle");
});
const sectionParagraphStyle = computed(() => {
    return getStyle(props.section.style, "userConsentingParagraph");
});
const sectionPrimaryColorStyle = computed(() => {
    return getStyle(props.section.style, "userConsentingPrimaryColor");
});
const sectionSecondaryColorStyle = computed(() => {
    return getStyle(props.section.style, "userConsentingSecondaryColor");
});
const formBackgroundStyle = computed(() => {
    return getStyle(props.section.getComponents()[0].style, "formBackground");
});
const formItemLabelStyle = computed(() => {
    return getStyle(props.section.getComponents()[0].style, "formItemLabel");
});
const formItemInputStyle = computed(() => {
    return getStyle(props.section.getComponents()[0].style, "formItemInput");
});
const formCheckboxStyle = computed(() => {
    return getStyle(props.section.getComponents()[0].style, "formCheckbox");
});
const formSubmitButtonStyle = computed(() => {
    return getStyle(props.section.getComponents()[0].style, "formSubmitButton");
});
const formSubmitButtonHoverStyle = computed(() => {
    return getStyle(
        props.section.getComponents()[0].style,
        "formSubmitButtonHover"
    );
});

const loginInputEmailTexts = computed(() => {
    return getFormItemText(
        props.section.getComponents()[0].getComponents()[0],
        ELoginFormItem.EMAIL
    );
});
const loginInputPasswordTexts = computed(() => {
    return getFormItemText(
        props.section.getComponents()[0].getComponents()[0],
        ELoginFormItem.PASSWORD
    );
});

const registrationInputFirstNameTexts = computed(() => {
    return getFormItemText(
        props.section.getComponents()[0].getComponents()[1],
        ERegistrationFormItem.FIRSTNAME
    );
});
const registrationInputLastNameTexts = computed(() => {
    return getFormItemText(
        props.section.getComponents()[0].getComponents()[1],
        ERegistrationFormItem.LASTNAME
    );
});
const registrationInputEmailTexts = computed(() => {
    return getFormItemText(
        props.section.getComponents()[0].getComponents()[1],
        ERegistrationFormItem.EMAIL
    );
});
const registrationInputPhoneTexts = computed(() => {
    return getFormItemText(
        props.section.getComponents()[0].getComponents()[1],
        ERegistrationFormItem.PHONE_NUMBER
    );
});
const registrationInputZipTexts = computed(() => {
    return getFormItemText(
        props.section.getComponents()[0].getComponents()[1],
        ERegistrationFormItem.ZIP
    );
});
const registrationInputCityTexts = computed(() => {
    return getFormItemText(
        props.section.getComponents()[0].getComponents()[1],
        ERegistrationFormItem.CITY
    );
});
const registrationInputAddressTexts = computed(() => {
    return getFormItemText(
        props.section.getComponents()[0].getComponents()[1],
        ERegistrationFormItem.ADDRESS
    );
});

//console.log("EZ MI EZ: ", backgroundStyle);

/**
 * Section and all component style
 */
const userConsentStyle = reactive({
    html: { overflow: "hidden" },
    sectionBackground: backgroundStyle,
    sectionTitle: sectionTitleStyle,
    sectionParagraph: sectionParagraphStyle,
    sectionPrimaryColor: sectionPrimaryColorStyle,
    sectionSecondaryColor: sectionSecondaryColorStyle,
    formBackground: formBackgroundStyle,
    formItemLabel: formItemLabelStyle,
    formItemInput: formItemInputStyle,
    formItemCheckbox: formCheckboxStyle,
    formSubmitButton: formSubmitButtonStyle,
    formSubmitButtonHover: formSubmitButtonHoverStyle,
    loginEmail: loginInputEmailTexts,
    loginPassword: loginInputPasswordTexts,
    registrationFirstName: registrationInputFirstNameTexts,
    registrationLastName: registrationInputLastNameTexts,
    registrationEmail: registrationInputEmailTexts,
    registrationPhoneNumber: registrationInputPhoneTexts,
    registrationZip: registrationInputZipTexts,
    registrationCity: registrationInputCityTexts,
    registrationAddress: registrationInputAddressTexts,

    loginSectionTitle: removeParagraph(props.section
        .getComponents()[0]
        ?.getComponents()[2]
        ?.textRaw),
    loginSectionParagraph: removeParagraph(props.section
        .getComponents()[0]
        ?.getComponents()[3]
        ?.textRaw),
    registrationSectionTitle: removeParagraph(props.section
        .getComponents()[0]
        ?.getComponents()[4]
        ?.textRaw),
    registrationSectionParagraph: removeParagraph(props.section
        .getComponents()[0]
        ?.getComponents()[5]
        ?.textRaw),
    acceptUserConsentSectionTitle: removeParagraph(props.section
        .getComponents()[0]
        ?.getComponents()[6]
        ?.textRaw),
    acceptUserConsentSectionParagraph: removeParagraph(props.section
        .getComponents()[0]
        ?.getComponents()[7]
        ?.textRaw),
});

function removeParagraph(text: any): any {
    if (text && typeof text == "string") {
        return text.replaceAll("<p>", "").replaceAll("</p>", "");
    }
    return text;
}

watch(
    () => [props.section.style, props.section.getComponents()],
    () => {
        //console.log("It's changed!");
        makeUrlHiveAuthUrl();
    }
);

async function makeUrlHiveAuthUrl() {
    authorizationUrl.value = await authStore.oauth2LoginStart(
        campaignDataStore.campaignUUId,
        userConsentStyle as any
    );
}
</script>

<style lang="scss">
.login-iframe {
    min-height: 500px;
    overflow: hidden;

    html {
        overflow: hidden;
    }
}
</style>
