<template>
    <section>
        <div
            class="py-5 overflow-hidden contact-us-wrapper"
            :style="[backgroundStyleObject, contactMergedStyle]"
        >
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-8 offset-md-2 text-center">
                        <h2
                            v-if="section.sectionTitleRaw"
                            class="fw-bold px-4"
                            :style="titleStyleObject"
                            v-html="section.sectionTitleRaw"
                        ></h2>
                        <div
                            :style="paragraphStyleObject"
                            v-html="section.sectionParagraphRaw"
                        ></div>
                        <div class="d-inline-block form-check mt-3">
                            <input
                                v-model="showContactBlock"
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="contactCheckbox"
                            />

                            <label
                                class="form-check-label ms-2"
                                for="contactCheckbox"
                                :style="paragraphStyleObject"
                                v-html="section.checkboxTextRaw"
                            >
                            </label>
                        </div>
                        <div
                            v-if="showContactBlock"
                            class="mt-4"
                            :style="paragraphStyleObject"
                            v-html="section.checkboxInnerTextRaw"
                        ></div>

                        <div
                            class="mt-4"
                            :style="paragraphStyleObject"
                            v-html="section.promotionRangeTextRaw"
                        ></div>
                    </div>
                    <!-- <a
                            class="d-inline-block fw-bold text-decoration-none text-uppercase mt-0 mb-5 mb-md-0 mt-md-6"
                            :style="buttonStyleObject"
                            :href="`mailto:${campaign.contactEmail}`"
                            v-html="contactButtonTextRaw"
                        ></a> -->
                </div>
            </div>
        </div>

        <!-- Prizific page builder tool -->
        <client-only>
            <PrizificPageBuilderTool v-if="editable" :section="section" />
        </client-only>
        <!-- Prizific page builder tool end -->
    </section>
</template>

<script lang="ts">
import { useCampaignDataStore } from "@/store/campaign/campaignData";
import { getStyle } from "../../composables/styleHelper";
import { EProperty } from "~~/enums/PrizificPageBuilder/EProperty";
export default {
    props: {
        section: {
            required: true,
        },
        editable: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        const campaignDataStore = useCampaignDataStore();
        const runtime = useRuntimeConfig();

        return {
            campaign: {
                contactEmail: campaignDataStore.campaignData.contactEmail,
                privacyPolicy: useMediaUrl(
                    campaignDataStore.campaignData.privacyPolicyPdfFile
                ),
            },
            showContactBlock: false,
            imageXs: `url('${useMediaUrl(this.section.images[0]?.src ?? "")}')`,
            imageSm: `url('${useMediaUrl(this.section.images[1]?.src ?? "")}')`,
            imageMd: `url('${useMediaUrl(this.section.images[2]?.src ?? "")}')`,
            imageLg: `url('${useMediaUrl(this.section.images[3]?.src ?? "")}')`,
            imageXl: `url('${useMediaUrl(this.section.images[4]?.src ?? "")}')`,
            imageXxl: `url('${useMediaUrl(
                this.section.images[5]?.src ?? ""
            )}')`,
        };
    },
    computed: {
        /**
         * Get background style
         * @returns {any} object
         */
        backgroundStyleObject(): any {
            return getStyle(this.section?.style ?? [], "contactBackground");
        },

        /**
         * Get background-image style
         * @returns {any} object
         */
        sectionBackgroundImage() {
            return this.section.src
                ? {
                      backgroundImage: `url(${useMediaUrl(
                          this.section?.src ?? ""
                      )})`,
                  }
                : "";
        },

        /**
         * Get title style
         * @returns {any} object
         */
        titleStyleObject(): any {
            return getStyle(this.section?.style ?? "", "contactTitle");
        },

        /**
         * Get paragraph style
         * @returns {any} object
         */
        paragraphStyleObject(): any {
            return getStyle(this.section?.style ?? "", "contactParagraph");
        },

        /**
         * Get contact checkbox style
         */
        checkboxStyleObject(): any {
            return getStyle(this.section?.style ?? "", "contactCheckbox");
        },

        /**
         * Get button style
         * @returns {any} object
         */
        buttonStyleObject(): any {
            return getStyle(
                this.section.components[0]?.style ?? [],
                "contactSubmitButton"
            );
        },

        contactMergedStyle(): any {
            return {
                "--contact-checkbox-active-color":
                    this.checkboxStyleObject[EProperty.COLOR],
                "--contact-checkbox-background-color":
                    this.checkboxStyleObject[EProperty.BACKGROUND_COLOR],
                "--contact-checkbox-border-color":
                    this.checkboxStyleObject[EProperty.BORDER_COLOR],
                "--contact-checkbox-border-radius":
                    this.checkboxStyleObject[EProperty.BORDER_RADIUS],
            };
        },

        /**
         * Get button text
         * @returns {string}
         */
        contactButtonText() {
            return this.section.components[0]?.text ?? "";
        },
        contactButtonTextRaw() {
            return this.section.components[0]?.textRaw ?? "";
        },
    },
};
</script>

<style lang="scss" scoped>
.contact-us-wrapper {
    background-image: v-bind("imageXs");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: 576px) {
        background-image: v-bind("imageSm");
    }

    @media (min-width: 768px) {
        background-image: v-bind("imageMd");
    }

    @media (min-width: 992px) {
        background-image: v-bind("imageLg");
    }

    @media (min-width: 1200px) {
        background-image: v-bind("imageXl");
    }

    @media (min-width: 1400px) {
        background-image: v-bind("imageXxl");
    }

    .form-check-label {
        cursor: pointer;
    }

    .form-check-input {
        position: relative;
        top: -5px;
        width: 24px;
        height: 24px;
        border-radius: 6px;
        padding: 2px;
        background-color: var(--contact-checkbox-background-color);
        border: 2px solid var(--contact-checkbox-border-color);
        cursor: pointer;

        &:checked {
            position: relative;

            &::after {
                position: absolute;
                content: "";
                width: 70%;
                height: 70%;
                top: 3px;
                left: 3px;
                border-radius: 3px;
                background: var(--contact-checkbox-active-color);
            }
        }
    }
}
</style>
