<template>
    <!-- Mobile Navbar -->
    <div>
        <ul
            ref="mobileNavbar"
            class="mobile-navbar d-flex gap-2 d-xl-none"
            :style="navbarBottomMergedStyle"
        >
            <MobileNavItem
                :className="[whichMenuIsActive(EMenus.GAME_STEPS)]"
                iconName="casino"
                name="Játék menete"
                navUrl="#jatek-menete"
                navUrlId="jatek-menete"
                :needAnimation="true"
                :playAnimation="data.playAnimation[0]"
                @click.native="setActiveMenu(EMenus.GAME_STEPS)"
            />
            <MobileNavItem
                :className="whichMenuIsActive(EMenus.PRIZES)"
                iconName="redeem"
                name="Nyeremények"
                navUrl="#nyeremenyek"
                navUrlId="nyeremenyek"
                :needAnimation="true"
                :playAnimation="data.playAnimation[1]"
                @click.native="setActiveMenu(EMenus.PRIZES)"
            />
            <MobileNavItem
                :className="whichMenuIsActive(EMenus.WINNERS)"
                iconName="emoji_events"
                name="Nyertesek"
                navUrl="nyertesek"
                :needAnimation="true"
                :playAnimation="data.playAnimation[2]"
                @click.native="setActiveMenu(EMenus.WINNERS)"
            />

            <MobileNavItem
                v-if="authStore.isAuthenticated"
                :className="whichMenuIsActive(EMenus.MENU)"
                iconName="menu"
                name="Menü"
                type="helper"
                :needAnimation="true"
                :playAnimation="data.playAnimation[3]"
                @click="toggleMenu"
                @click.native="setActiveMenu(EMenus.MENU)"
            />
        </ul>

        <transition name="menu">
            <div
                v-if="data.menuState"
                :style="navbarBottomMergedStyle"
                class="mobile-navbar-menu d-flex flex-column align-items-center py-4"
            >
                <div class="mobile-navbar-menu-close">
                    <span @click="toggleMenu" class="material-icons-outlined">
                        highlight_off
                    </span>
                </div>
                <h3>Menü</h3>
                <a
                    v-if="
                        authStore.isAuthenticated &&
                        campaignDataStore.isHiveConnection
                    "
                    class="mobile-navbar-menu-item"
                    :href="hiveProfile"
                >
                    Profil módosítás
                </a>
                <a
                    v-if="
                        authStore.isAuthenticated &&
                        campaignDataStore.isHiveConnection
                    "
                    class="mobile-navbar-menu-item"
                    :href="hiveChangePassword"
                >
                    Jelszó módosítás
                </a>
                <a
                    v-if="
                        authStore.isAuthenticated &&
                        !campaignDataStore.isHiveConnection
                    "
                    class="mobile-navbar-menu-item"
                    :href="useDynamicLink('/profil-modositas')"
                >
                    Profil módosítás
                </a>
                <a
                    v-if="
                        authStore.isAuthenticated &&
                        !campaignDataStore.isHiveConnection
                    "
                    class="mobile-navbar-menu-item"
                    :href="useDynamicLink('/jelszo-modositas')"
                >
                    Jelszó módosítás
                </a>
                <a
                    v-if="authStore.isAuthenticated"
                    @click="logOut"
                    class="mobile-navbar-menu-item"
                    type="button"
                >
                    Kijelentkezés
                </a>
            </div>
        </transition>
    </div>
    <!-- Mobile Navbar end -->
</template>

<script setup lang="ts">
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { EProperty } from "~~/enums/PrizificPageBuilder/EProperty";
import { IComponent } from "~~/interfaces/PrizificPageBuilder/IComponent";
import { INavItem } from "~~/interfaces/PrizificPageBuilder/INavItem";
import { useAuthStore } from "@/store/user/auth";
import { useCampaignDataStore } from "~~/store/campaign/campaignData";

const authStore = useAuthStore();
const campaignDataStore: any = useCampaignDataStore();

interface Props {
    section: any;
}

enum EMenus {
    GAME_STEPS = "jatekmenet",
    PRIZES = "nyeremenyek",
    WINNERS = "nyertesek",
    MENU = "menu",
}

/**
 * Define props
 */
const props = defineProps<Props>();

const data = reactive({
    document: null as any,
    window: null as any,
    menuState: false,
    activeMenu: "",
    playAnimation: [false, false, false, false],
});

const mobileNavbar = ref();

watch(
    () => authStore.isAuthenticated,
    (newVal) => {
        if (newVal) {
            data.playAnimation[3] = false;
            startAnimations();
        }
    }
);

onMounted(() => {
    data.document = document;
    data.window = window;

    mobileNavAnimation();
});

async function mobileNavAnimation() {
    await mobileNavbar.value.classList.add("loaded");

    startAnimations();
}

async function startAnimations() {
    for (const [index, value] of data.playAnimation.entries()) {
        await delay(100);
        data.playAnimation[index] = true;
    }
}

async function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

const hiveProfile = computed(() => {
    return authStore.hiveUrl(`/profile?redirectOrigin=${window.location.href}`);
});

const hiveChangePassword = computed(() => {
    return authStore.hiveUrl(
        `/change-password?redirectOrigin=${window.location.href}`
    );
});

/**
 * Toggle menu
 */
function toggleMenu() {
    data.menuState = !data.menuState;
}

/**
 * Set active menu
 */
function setActiveMenu(name: EMenus) {
    data.activeMenu = name;
}

/**
 * Return with active string
 *
 * @param menu
 * @returns {string} active
 */
function whichMenuIsActive(menu: EMenus) {
    if (data.activeMenu !== menu) {
        return "";
    }

    return "active";
}

/**
 * Logout
 */
function logOut() {
    authStore.logOut().then(() => {
        // location.reload();
        toggleMenu();
    });
}

/**
 * Navbar bottom computed style
 */
const navbarBottomStyle = computed(() => {
    return getStyle(props.section.style, "mobileNavbar");
});

const navbarBottomHoverStyle = computed(() => {
    return getStyle(props.section.style, "mobileNavbarHover");
});

const navbarBottomMergedStyle = computed(() => {
    return {
        "--mobile-navbar-background-color":
            navbarBottomStyle.value[EProperty.BACKGROUND_COLOR],
        "--mobile-navbar-text-color": navbarBottomStyle.value[EProperty.COLOR],
        "--mobile-navbar-text-hover-color":
            navbarBottomHoverStyle.value[EProperty.COLOR],
    };
});
</script>

<style lang="scss" scoped>
.mobile-navbar {
    position: fixed;
    z-index: 9999;
    bottom: -32px;
    left: 0;
    padding: 0;
    opacity: 0;
    width: 100%;
    min-height: 64px;
    margin-bottom: 0;
    background-color: var(--mobile-navbar-background-color);
    box-shadow: 0 -5px 10px 0 rgb(0 0 0 / 10%);

    &.loaded {
        bottom: 0;
        opacity: 1;
        transition: opacity 0.2s ease-in-out, bottom 0.2s ease-in-out;
    }
}

.mobile-navbar-menu {
    position: fixed;
    z-index: 10000;
    top: 56px;
    left: 0;
    bottom: 64px;
    width: 100%;
    height: calc(100% - 120px);
    background-color: var(--mobile-navbar-background-color);

    a.nuxt-link-exact-active {
        color: var(--mobile-navbar-text-hover-color);
    }

    h3 {
        color: var(--mobile-navbar-text-color);
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 1.5rem;
    }

    .mobile-navbar-menu-item {
        text-decoration: none;
        font-weight: bold;
        color: var(--mobile-navbar-text-color);
        font-size: 16px;
        padding: 0.5rem 0.25rem;
    }

    .mobile-navbar-menu-close {
        width: 100%;
        margin-right: 2rem;
        text-align: right;
        cursor: pointer;

        .material-icons-outlined {
            font-size: 32px;
            color: var(--mobile-navbar-text-color);
        }
    }
}

.menu-enter-active,
.menu-leave-active {
    transition: transform 0.45s;
    z-index: 9998;
    transform: translateY(0);
}
.menu-enter,
.menu-leave-to {
    z-index: 9998;
    transform: translateY(200%);
}
</style>
