<template>
    <main id="age-gate" :style="backgroundStyle">
        <header class="position-relative">
            <div class="container">
                <div class="text-center">
                    <el-image class="ms-2 ms-md-0 me-12 mt-6 mb-6" :style="brandLogoStyle" :src="useMediaUrl(logoSrc)">
                        <div slot="placeholder" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </el-image>
                </div>
            </div>

            <!-- Prizific page builder tool -->
            <client-only>
                <PrizificPageBuilderTool v-if="editable" :editable="editable" :section="section" />
            </client-only>
            <!-- Prizific page builder tool end -->
        </header>
        <section>
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-6 offset-md-3">
                        <h2 class="text-center mb-5" :style="sectionTitleStyle" v-html="section.sectionTitle">
                        </h2>
                        <div class="description text-center mb-5" :style="sectionParagraphStyle"
                            v-html="section.sectionParagraph">

                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section :style="ageGateFormMergedStyle">
            <div class="container">
                <div class="age-gate-label text-center mb-5 mt-2">
                    <h2 v-if="data.activePart == 'year'">Év</h2>
                    <h2 v-if="data.activePart == 'month'">Hónap</h2>
                    <h2 v-if="data.activePart == 'day'">Nap</h2>
                </div>
            </div>

            <div class="date-of-birth" @touchstart="handleTouchStart" @touchmove="handleTouchMove"
                @touchend="handleTouchEnd">
                <div ref="dateOfBirthWrapper" class="date-of-birth-wrapper">
                    <div ref="year" class="date-of-birth-inputs">
                        <template v-for="(item, index) in data.dateOfBirthInputs.year" :key="'year' + index">
                            <input :id="`inputYear${index}`" ref="inputYear" v-model="item.value"
                                @keyup.enter="handleSubmitButton" @keyup="
                                    nextInput(inputYear, 'inputYear', index)
                                    " @keydown.backspace="
                                        prevInput(
                                            $event,
                                            inputYear,
                                            'inputYear',
                                            index
                                        )
                                        " @keydown.tab="
                                            handleTab($event, 'inputYear', index)
                                            " @keypress="onlyNumber($event)" @click="
                                                handleClick(inputYear, 'inputYear', index)
                                                " @focus="
                                                    data.activePart = EAgeGateActivePart.YEAR
                                                    " type="text" maxlength="1" autocomplete="off" pattern="[0-9]*"
                                inputmode="numeric" />
                        </template>
                    </div>
                    <div :class="[
                        'date-of-birth-inputs',
                        { hide: !isYearEqualWithEighteen },
                    ]" ref="month">
                        <template v-for="(item, index) in data.dateOfBirthInputs
                            .month" :key="'month' + index">
                            <input :id="`inputMonth${index}`" ref="inputMonth" v-model="item.value"
                                @keyup.enter="handleSubmitButton" @keyup="
                                    nextInput(inputMonth, 'inputMonth', index)
                                    " @input="
                                        nextInput(inputMonth, 'inputMonth', index)
                                        " @keydown.backspace="
                                            prevInput(
                                                $event,
                                                inputMonth,
                                                'inputMonth',
                                                index
                                            )
                                            " @keydown.tab="
                                                handleTab($event, 'inputMonth', index)
                                                " @keypress="onlyNumber($event)" @click="
                                                    handleClick(inputMonth, 'inputMonth', index)
                                                    " @focus="
                                                        data.activePart = EAgeGateActivePart.MONTH
                                                        " type="text" maxlength="1" autocomplete="off" pattern="[0-9]*"
                                inputmode="numeric" />
                        </template>
                    </div>
                    <div ref="day" :class="[
                        'date-of-birth-inputs',
                        { hide: !isMonthEqualWithEighteen },
                    ]">
                        <template v-for="(item, index) in data.dateOfBirthInputs.day" :key="'day' + index">
                            <input :id="`inputDay${index}`" ref="inputDay" v-model="item.value"
                                @keyup.enter="handleSubmitButton" @keyup="nextInput(inputDay, 'inputDay', index)"
                                @keydown.backspace="
                                    prevInput(
                                        $event,
                                        inputDay,
                                        'inputDay',
                                        index
                                    )
                                    " @keypress="onlyNumber($event)" @click="
                                        handleClick(inputDay, 'inputDay', index)
                                        " @focus="
                                            data.activePart = EAgeGateActivePart.DAY
                                            " type="text" maxlength="1" autocomplete="off" pattern="[0-9]*"
                                inputmode="numeric" />
                        </template>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="date-of-birth-checkbox-wrapper text-center mt-6">
                    <el-checkbox class="checkbox" v-model="data.saveAge">
                        <span>Dátum megjegyzése * </span>
                    </el-checkbox>

                    <div class="mt-5">
                        <button class="date-of-birth-submit" @click="handleSubmitButton" :disabled="!isYearBiggerThanEighten &&
                            !isMonthBiggerThanEighteen &&
                            !isDayBiggerThanEighteen &&
                            !isUnderage
                            ">
                            Tovább
                        </button>
                    </div>
                    <small class="d-block mt-6 mb-6">A játékban csak 18 éven felüliek vehetnek részt.</small>

                    <div class="fw-bold">
                        * Kérjük, ne jelöld be ezt a négyzetet, ha a
                        számítógépet 18 év alattiak is használják! A weboldal a
                        böngészés élmény javítása érdekében sütiket helyez el a
                        böngészőben. Ha többet szeretnél megtudni a sütikről,
                        hogy azokat miként használja az oldal, illetve az
                        adataid védelméről, tekintsd meg a sütikről szóló
                        tájékoztató oldalunkat. A sütik felhasználásáról
                        bármikor tájékozódhatsz a weboldal bármely oldalának
                        alján megtalálható hivatkozás segítségével.
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script setup lang="ts">
import { ElCheckbox, ElImage } from "element-plus";
import { useCampaignDataStore } from "@/store/campaign/campaignData";
import { EProperty } from "~/enums/PrizificPageBuilder/EProperty";
import { EAgeGateActivePart } from "~/enums/Campaign/EAgeGate";
import { getStyle } from "../../composables/styleHelper";
import DateOfBirth from "./DateOfBirth";
import debounce from "lodash.debounce";
import _ from "lodash";
import moment from "moment";
import { EComponents } from "~/enums/PrizificPageBuilder/EComponents";

const runtime = useRuntimeConfig();
const campaignDataStore: any = useCampaignDataStore();

interface Props {
    section: any;
    editable: boolean;
}

/**
 * Define props
 */
const props = defineProps<Props>();

const dateOfBirthWrapper: any = ref(null);
const year: any = ref(null);
const month: any = ref(null);
const day: any = ref(null);
const inputYear: any = ref([]);
const inputMonth: any = ref([]);
const inputDay: any = ref([]);

const data = reactive({
    dateOfBirth: null as any,
    dateOfBirthInputs: {
        year: [{ value: "" }, { value: "" }, { value: "" }, { value: "" }],
        month: [{ value: "" }, { value: "" }],
        day: [{ value: "" }, { value: "" }],
    },
    touchInfo: {
        start: 0,
        move: 0,
    },
    activePart: EAgeGateActivePart.YEAR,
    saveAge: false,
});

onMounted(() => {
    //campaignDataStore.getCampaignData();

    data.dateOfBirth = new DateOfBirth(
        window,
        dateOfBirthWrapper.value,
        year.value,
        month.value,
        day.value
    );

    data.dateOfBirth.init();

    window?.addEventListener("resize", () => {
        data.dateOfBirth.resize();
        goToActivePart();
    });
});

onBeforeUnmount(() => {
    window?.removeEventListener("resize", goToActivePart);
    window?.addEventListener("beforeunload", () => { }, false);
});

/**
 * Assembled year
 */
const assembledYear = computed(() => {
    return parseInt(
        `${data.dateOfBirthInputs?.year[0].value}${data.dateOfBirthInputs?.year[1].value}${data.dateOfBirthInputs?.year[2].value}${data.dateOfBirthInputs?.year[3].value}`
    );
});

/**
 * Assembled month
 */
const assembledMonth = computed(() => {
    return parseInt(
        `${data.dateOfBirthInputs?.month[0].value}${data.dateOfBirthInputs?.month[1].value}`
    );
});

/**
 * Assembled day
 */
const assembledDay = computed(() => {
    return parseInt(
        `${data.dateOfBirthInputs?.day[0].value}${data.dateOfBirthInputs?.day[1].value}`
    );
});

/**
 * Is underage
 */
const isUnderage = computed(() => {
    const assembledY = assembledYear.value ? (assembledYear.value as any) : "";
    const assembledM = assembledMonth.value ? (assembledMonth.value as any) : 1;
    const assembledD = assembledDay.value ? (assembledDay.value as any) : 1;
    const currentYear = moment().year();
    const currentMonth = moment().month();
    const currentDay = moment().date();

    const assembledDate = moment([assembledY, assembledM - 1, assembledD]);
    const currentDate = moment([currentYear, currentMonth, currentDay]);

    const dateDifference = currentDate.diff(assembledDate, "years", true);

    if (Number.isNaN(dateDifference)) {
        return;
    }

    return (
        dateDifference < 18 &&
        Boolean(data.dateOfBirthInputs?.year[0].value.length) &&
        Boolean(data.dateOfBirthInputs?.year[1].value.length) &&
        Boolean(data.dateOfBirthInputs?.year[2].value.length) &&
        Boolean(data.dateOfBirthInputs?.year[3].value.length)
    );
});

/**
 * Typed year is bigger than eighteen
 */
const isYearBiggerThanEighten = computed(() => {
    const assembledY = assembledYear.value ? (assembledYear.value as any) : "";
    const currentYear = moment().year();
    const birthYear = currentYear - assembledY;

    return (
        18 < birthYear &&
        Boolean(data.dateOfBirthInputs?.year[0].value.length) &&
        Boolean(data.dateOfBirthInputs?.year[1].value.length) &&
        Boolean(data.dateOfBirthInputs?.year[2].value.length) &&
        Boolean(data.dateOfBirthInputs?.year[3].value.length)
    );
});

/**
 * Typed month is bigger than 18
 */
const isMonthBiggerThanEighteen = computed(() => {
    const assembledY = assembledYear.value ? (assembledYear.value as any) : "";
    const assembledM = assembledMonth.value
        ? (assembledMonth.value as any)
        : NaN;
    const currentYear = moment().year();
    const currentMonth = moment().month();

    const assembledDate = moment([assembledY, assembledM - 1, 1]);
    const currentDate = moment([currentYear, currentMonth, 1]);

    const dateDifference = currentDate.diff(assembledDate, "years", true);

    return (
        !Number.isNaN(assembledM) &&
        18 < dateDifference &&
        Boolean(data.dateOfBirthInputs?.year[0].value.length) &&
        Boolean(data.dateOfBirthInputs?.year[1].value.length) &&
        Boolean(data.dateOfBirthInputs?.year[2].value.length) &&
        Boolean(data.dateOfBirthInputs?.year[3].value.length) &&
        Boolean(data.dateOfBirthInputs?.month[0].value.length) &&
        Boolean(data.dateOfBirthInputs?.month[1].value.length)
    );
});

/**
 * Typed day is bigger than 18
 */
const isDayBiggerThanEighteen = computed(() => {
    const assembledY = assembledYear.value ? (assembledYear.value as any) : "";
    const assembledM = assembledMonth.value
        ? (assembledMonth.value as any)
        : NaN;
    const assembledD = assembledDay.value ? (assembledDay.value as any) : NaN;
    const currentYear = moment().year();
    const currentMonth = moment().month();
    const currentDay = moment().date();

    const assembledDate = moment([assembledY, assembledM - 1, assembledD]);
    const currentDate = moment([currentYear, currentMonth, currentDay]);

    const dateDifference = currentDate.diff(assembledDate, "years", true);

    return (
        18 <= dateDifference &&
        Boolean(data.dateOfBirthInputs?.year[0].value.length) &&
        Boolean(data.dateOfBirthInputs?.year[1].value.length) &&
        Boolean(data.dateOfBirthInputs?.year[2].value.length) &&
        Boolean(data.dateOfBirthInputs?.year[3].value.length) &&
        Boolean(data.dateOfBirthInputs?.month[0].value.length) &&
        Boolean(data.dateOfBirthInputs?.month[1].value.length) &&
        Boolean(data.dateOfBirthInputs?.day[0].value.length) &&
        Boolean(data.dateOfBirthInputs?.day[1].value.length)
    );
});

/**
 * Typed year is equal with 18
 */
const isYearEqualWithEighteen = computed(() => {
    const assembledY = assembledYear.value ? (assembledYear.value as any) : "";
    const currentYear = moment().year();
    const birthYear = currentYear - assembledY;

    return (
        18 == birthYear &&
        Boolean(data.dateOfBirthInputs?.year[0].value.length) &&
        Boolean(data.dateOfBirthInputs?.year[1].value.length) &&
        Boolean(data.dateOfBirthInputs?.year[2].value.length) &&
        Boolean(data.dateOfBirthInputs?.year[3].value.length)
    );
});

/**
 * Typed month is equal with 18
 */
const isMonthEqualWithEighteen = computed(() => {
    const assembledY = assembledYear.value ? (assembledYear.value as any) : "";
    const assembledM = assembledMonth.value
        ? (assembledMonth.value as any)
        : NaN;
    const currentYear = moment().year();
    const currentMonth = moment().month();

    const assembledDate = moment([assembledY, assembledM - 1, 1]);
    const currentDate = moment([currentYear, currentMonth, 1]);

    const dateDifference = currentDate.diff(assembledDate, "years", true);

    return (
        18 == dateDifference &&
        Boolean(data.dateOfBirthInputs?.year[0].value.length) &&
        Boolean(data.dateOfBirthInputs?.year[1].value.length) &&
        Boolean(data.dateOfBirthInputs?.year[2].value.length) &&
        Boolean(data.dateOfBirthInputs?.year[3].value.length) &&
        Boolean(data.dateOfBirthInputs?.month[0].value.length) &&
        Boolean(data.dateOfBirthInputs?.month[1].value.length)
    );
});

/**
 * Typed day is equal with 18
 */
const isDayEqualWithEighteen = computed(() => {
    const assembledY = assembledYear.value ? (assembledYear.value as any) : "";
    const assembledM = assembledMonth.value
        ? (assembledMonth.value as any)
        : NaN;
    const assembledD = assembledDay.value ? (assembledDay.value as any) : 1;
    const currentYear = moment().year();
    const currentMonth = moment().month();
    const currentDay = moment().date();

    const assembledDate = moment([assembledY, assembledM - 1, assembledD]);
    const currentDate = moment([currentYear, currentMonth, currentDay]);

    const dateDifference = currentDate.diff(assembledDate, "years", true);

    return (
        18 == dateDifference &&
        Boolean(data.dateOfBirthInputs?.year[0].value.length) &&
        Boolean(data.dateOfBirthInputs?.year[1].value.length) &&
        Boolean(data.dateOfBirthInputs?.year[2].value.length) &&
        Boolean(data.dateOfBirthInputs?.year[3].value.length) &&
        Boolean(data.dateOfBirthInputs?.month[0].value.length) &&
        Boolean(data.dateOfBirthInputs?.month[1].value.length) &&
        Boolean(data.dateOfBirthInputs?.day[0].value.length) &&
        Boolean(data.dateOfBirthInputs?.day[1].value.length)
    );
});

const goToActivePart = debounce(() => {
    if (data.activePart === EAgeGateActivePart.YEAR) {
        data.dateOfBirth.goToYear();
    } else if (data.activePart === EAgeGateActivePart.MONTH) {
        data.dateOfBirth.goToMonth();
    } else if (data.activePart === EAgeGateActivePart.DAY) {
        data.dateOfBirth.goToDay();
    }
}, 50);

/**
 * Stepping next input fields if input has value
 *
 * @param refElement - ref elements
 * @param refName - string
 * @param refIndex - number
 */
function nextInput(refElement: any, refName: string, refIndex: number): void {
    if (
        refElement[refIndex] &&
        refElement[refIndex].value &&
        refElement[refIndex].value.length
    ) {
        const nextInput = refElement[refIndex + 1]
            ? refElement[refIndex + 1]
            : "";

        if (
            refName == "inputYear" &&
            refIndex < data.dateOfBirthInputs?.year?.length - 1
        ) {
            nextInput.setSelectionRange(
                nextInput.value.length,
                nextInput.value.length
            );
            nextInput.focus();
        } else if (refName == "inputYear") {
            if (isYearEqualWithEighteen.value) {
                data.dateOfBirth.goToMonth();

                inputMonth.value[0].setSelectionRange(
                    inputMonth.value[0].value.length,
                    inputMonth.value[0].value.length
                );
                inputMonth.value[0].focus();
            }
        }

        if (
            refName == "inputMonth" &&
            refIndex < data.dateOfBirthInputs?.month?.length - 1
        ) {
            nextInput.setSelectionRange(
                nextInput.value.length,
                nextInput.value.length
            );
            nextInput.focus();
        } else if (refName == "inputMonth") {
            if (
                isMonthEqualWithEighteen.value &&
                !isMonthBiggerThanEighteen.value
            ) {
                data.dateOfBirth.goToDay();

                inputDay.value[0].setSelectionRange(
                    inputDay.value[0].value.length,
                    inputDay.value[0].value.length
                );
                inputDay.value[0].focus();
            }
        }

        if (
            refName == "inputDay" &&
            refIndex < data.dateOfBirthInputs?.day?.length - 1
        ) {
            nextInput.setSelectionRange(
                nextInput.value.length,
                nextInput.value.length
            );
            nextInput.focus();
        }
    }
}

/**
 * Stepping previous input fields if input has no value and press backspace
 *
 * @param refName - string
 * @param refIndex - number
 */
function prevInput(
    event: any,
    refElement: any,
    refName: string,
    refIndex: number
): void {
    if (!event.value) {
        const currentInput = refElement[refIndex] ? refElement[refIndex] : "";
        const prevInput = refElement[refIndex - 1]
            ? refElement[refIndex - 1]
            : "";

        if (
            refName == "inputYear" &&
            refIndex > 0 &&
            !currentInput.value.length
        ) {
            prevInput.focus();
        }

        if (
            refName == "inputMonth" &&
            refIndex > 0 &&
            !currentInput.value.length
        ) {
            prevInput.focus();
        } else if (refName == "inputMonth" && !currentInput.value.length) {
            data.dateOfBirth.goToYear();
            inputYear.value[3].focus();
        }

        if (
            refName == "inputDay" &&
            refIndex > 0 &&
            !currentInput.value.length
        ) {
            prevInput.focus();
        } else if (refName == "inputDay" && !currentInput.value.length) {
            data.dateOfBirth.goToMonth();
            inputMonth.value[1].focus();
        }
    }
}

/**
 * Check input typed character is not a number then returnValue false
 *
 * @param $event
 * @param refName - string
 * @param refIndex - number
 */
function onlyNumber(event: any) {
    const evt = event || window.event;

    if (evt.type === "paste") {
        key = evt.clipboardData.getData("text/plain");
    } else {
        var key = evt.keyCode || evt.which;
        key = String.fromCharCode(key);
    }

    const regex = /[0-9]|\./;

    if (evt.which === 9) {
        evt.preventDefault();
        return false;
    }

    if (!regex.test(key)) {
        evt.returnValue = false;

        if (evt.preventDefault && evt.which == 9) {
            evt.preventDefault();
        }
    }
}

/**
 * On click go to the right part
 */
function handleClick(refElement: any, refName: any, refIndex: number) {
    const currentInput = refElement[refIndex] ? refElement[refIndex] : "";

    goToActivePart();

    currentInput.setSelectionRange(
        currentInput.value.length,
        currentInput.value.length
    );
    currentInput.focus();
}

/**
 * Handle keyboard tab
 *
 * @param $event
 * @param refName
 * @param refIndex
 */
function handleTab($event: KeyboardEvent, refName: string, refIndex: number) {
    if (
        refName == "inputYear" &&
        refIndex == 3 &&
        !isYearEqualWithEighteen.value
    ) {
        $event.preventDefault();
    }

    if (
        refName == "inputMonth" &&
        refIndex == 1 &&
        !isMonthEqualWithEighteen.value
    ) {
        $event.preventDefault();
    }

    goToActivePart();
}

/**
 * Handle touch start
 */
function handleTouchStart(event: TouchEvent) {
    // this.$set(this.touchInfo, "start", event.touches[0].clientX);
    data.touchInfo.start = event.touches[0].clientX;
}

/**
 * Handle touch move
 */
function handleTouchMove(event: TouchEvent) {
    // this.$set(this.touchInfo, "move", event.touches[0].clientX);
    data.touchInfo.move = event.touches[0].clientX;
}

/**
 * Handle touch end
 */
function handleTouchEnd() {
    if (data.touchInfo.start > data.touchInfo.move) {
        // console.log("move left");
        if (data.activePart == EAgeGateActivePart.YEAR) {
            if (isYearEqualWithEighteen.value) {
                data.dateOfBirth.goToMonth();
                data.activePart = EAgeGateActivePart.MONTH;
            }
        } else if (data.activePart == EAgeGateActivePart.MONTH) {
            if (isMonthEqualWithEighteen.value) {
                data.dateOfBirth.goToDay();
                data.activePart = EAgeGateActivePart.DAY;
            }
        }
    } else if (data.touchInfo.start < data.touchInfo.move) {
        // console.log("move right");
        if (data.activePart == EAgeGateActivePart.DAY) {
            data.dateOfBirth.goToMonth();
            data.activePart = EAgeGateActivePart.MONTH;
        } else if (data.activePart == EAgeGateActivePart.MONTH) {
            data.dateOfBirth.goToYear();
            data.activePart = EAgeGateActivePart.YEAR;
        }
    }
}

/**
 * Handle submit button click
 */
async function handleSubmitButton() {
    if (
        isYearBiggerThanEighten.value ||
        isMonthBiggerThanEighteen.value ||
        isDayBiggerThanEighteen.value
    ) {
        // console.info("🍺");
        useCookie("adult").value = "true";

        await navigateTo("/");
    } else {
        // console.info("🍼");
        // useCookie("adult").value = "false";

        await navigateTo("/nem-vagy-eleg-idos");
    }
}

/**
 * Brand logo image computed source
 */
const logoSrc = computed(() => {
    let image = "";

    props.section.getComponents().forEach((component: any) => {
        if (component.type === EComponents.IMAGE) {
            image = component.src;
        }
    });

    return image;
});

const ageGateComponent = props.section.components[1];

// Computed form and form items style
const backgroundStyle = computed(() => {
    return getStyle(props.section.style, "ageGateBackground");
});
const brandLogoStyle = computed(() => {
    return getStyle(props.section.components[0].style, "");
});
const sectionTitleStyle = computed(() => {

    return getStyle(props.section.style, "ageGateTitle");
});
const sectionParagraphStyle = computed(() => {
    return getStyle(props.section.style, "ageGateParagraph");
});
const ageGateFormItemLabelStyle = computed(() => {
    return getStyle(props.section.components[0].style, "ageGateFormItemLabel");
});
const ageGateFormItemInputStyle = computed(() => {
    return getStyle(props.section.components[0].style, "ageGateFormItemInput");
});
const ageGateSubmitStyle = computed(() => {
    return getStyle(
        props.section.components[0].style,
        "ageGateFormSubmitButton"
    );
});
const ageGateInfoTextStyle = computed(() => {
    return getStyle(props.section.components[0].style, "ageGateInfoText");
});

function getValueSafe(val: any, key: string) {
    try {
        return val[key];
    } catch (e) {
        console.error("val", val, "key", key, e);
    }
    return '';
}

//TODO: create computed form style
const ageGateFormMergedStyle = computed(() => {
    return {
        "--age-gate-label-color":
            getValueSafe(ageGateFormItemLabelStyle.value, EProperty.COLOR),
        "--age-gate-input-border-radius":
            getValueSafe(ageGateFormItemInputStyle.value, EProperty.BORDER_RADIUS),
        "--age-gate-input-border-width":
            getValueSafe(ageGateFormItemInputStyle.value, EProperty.BORDER_WIDTH),
        "--age-gate-input-border-color":
            getValueSafe(ageGateFormItemInputStyle.value, EProperty.BORDER_COLOR),
        "--age-gate-input-color":
            getValueSafe(ageGateFormItemInputStyle.value, EProperty.COLOR),
        "--age-gate-submit-color":
            getValueSafe(ageGateSubmitStyle.value, EProperty.COLOR),
        "--age-gate-submit-font-size":
            getValueSafe(ageGateSubmitStyle.value, EProperty.FONT_SIZE),
        "--age-gate-submit-border-radius":
            getValueSafe(ageGateSubmitStyle.value, EProperty.BORDER_RADIUS),
        "--age-gate-submit-padding":
            getValueSafe(ageGateSubmitStyle.value, EProperty.PADDING),
        "--age-gate-submit-background-color":
            getValueSafe(ageGateSubmitStyle.value, EProperty.BACKGROUND_COLOR),
        "--age-gate-info-text-color":
            getValueSafe(ageGateInfoTextStyle.value, EProperty.COLOR),
    };
});
watch(
    () => _.cloneDeep(data.dateOfBirthInputs),
    (newValue) => {
        if (newValue.month[0].value == "0" && newValue.month[1].value == "0") {
            data.dateOfBirthInputs.month[1].value = "1";
        } else if (
            newValue.month[0].value != "" &&
            newValue.month[0].value != "0" &&
            newValue.month[0].value != "1"
        ) {
            data.dateOfBirthInputs.month[0].value = "0";
            data.dateOfBirthInputs.month[1].value = newValue.month[0].value;
        } else if (
            newValue.month[0].value == "1" &&
            newValue.month[1].value != "" &&
            newValue.month[1].value != "0" &&
            newValue.month[1].value != "1" &&
            newValue.month[1].value != "2"
        ) {
            data.dateOfBirthInputs.month[1].value = "2";
        } else if (
            newValue.day[0].value == "0" &&
            newValue.day[1].value == "0"
        ) {
            data.dateOfBirthInputs.day[1].value = "1";
        } else if (
            newValue.day[0].value != "" &&
            newValue.day[0].value != "0" &&
            newValue.day[0].value != "1" &&
            newValue.day[0].value != "2" &&
            newValue.day[0].value != "3"
        ) {
            data.dateOfBirthInputs.day[0].value = "3";
        } else if (
            newValue.day[0].value == "3" &&
            newValue.day[1].value != "" &&
            newValue.day[1].value != "0" &&
            newValue.day[1].value != "1"
        ) {
            data.dateOfBirthInputs.day[1].value = "1";
        } else if (newValue.year[0].value == "0") {
            data.dateOfBirthInputs.year[0].value = "1";
        }
    }
);
</script>

<style lang="scss">
#age-gate {
    min-height: 100vh;
}

.age-gate-label {
    color: var(--age-gate-label-color);
}

.date-of-birth {
    position: relative;
    display: block;
    overflow: hidden;

    .date-of-birth-wrapper {
        position: relative;
        display: flex;
        top: 0;
        left: 0;
        right: 0;
        gap: 64px;
        transition: transform 0.25s ease-in-out;

        .date-of-birth-inputs {
            display: flex;
            gap: 24px;

            &.hide {
                display: none;
                pointer-events: none;
            }

            input {
                border: 0;
                width: 64px;
                height: 64px;
                font-size: 30px;
                border-radius: var(--age-gate-input-border-radius) !important;
                border-width: var(--age-gate-input-border-width);
                border-color: var(--age-gate-input-border-color);
                text-align: center;
                color: var(--age-gate-input-color);
                font-weight: bold;

                &:focus {
                    border: 0;
                    outline: none;
                }
            }
        }
    }

    @media (min-width: 768px) {
        .date-of-birth-wrapper {
            gap: 128px;

            .date-of-birth-inputs {
                input {
                    width: 128px;
                    height: 128px;
                    font-size: 60px;
                    border-radius: 10px;
                }
            }
        }
    }
}

.date-of-birth-checkbox-wrapper {
    color: var(--age-gate-info-text-color);

    .el-checkbox {
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto !important;
    }

    .el-checkbox__label {
        white-space: normal;
        word-wrap: break-word;
        font-size: 16px;
        font-weight: 500;
        color: var(--age-gate-label-color) !important;

        p {
            margin-bottom: 0;
        }
    }

    .el-checkbox__inner {
        width: 24px;
        height: 24px;
        border-radius: 0.35rem;

        &::after {
            border-width: 3px;
            width: 5px;
            height: 12px;
            left: 7px;
            top: 2px;
            transition: initial;
            border-color: var(--age-gate-input-color) !important;
        }
    }

    .el-checkbox__input.is-checked+.el-checkbox__label {
        color: var(--age-gate-label-color) !important;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: white !important;
        border-color: white !important;
    }
}

.date-of-birth-submit {
    border: 0;
    color: var(--age-gate-submit-color);
    font-size: var(--age-gate-submit-font-size);
    border-radius: var(--age-gate-submit-border-radius);
    font-weight: 400;
    padding: var(--age-gate-submit-padding);
    background-color: var(--age-gate-submit-background-color);

    &:disabled {
        background: grey !important;
        color: white !important;
    }
}
</style>
