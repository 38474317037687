import { defineStore } from "pinia";
import moment from "moment";
import { ECodeUploadFormItem } from "~/enums/PrizificPageBuilder/ECodeUploadFormItem";
import { EPurchaseType } from "~/enums/Campaign/EPurchaseType";
import { useCampaignDataStore } from "~/store/campaign/campaignData";
import { usePurchasedPromotionProductStore } from "@/store/components/purchasedPromotionProduct/purchasedPromotionProduct";
import { useAuthStore } from "~~/store/user/auth";

export const useCodeUploadStore = defineStore("codeUpload", {
    state: () => {
        return {
            dynamicForm: {} as any,
            dynamicFieldList: [] as Array<any>,
            receiptsImagesRaw: undefined as any,
        };
    },
    getters: {
        userId() {
            const authStore = useAuthStore();

            return authStore.user ? authStore.user.id : undefined;
        },
        hasDynamicField(state) {
            return (fieldType: string) => {
                return (
                    state.dynamicFieldList.findIndex(
                        (field: any) => field.type === fieldType
                    ) != -1
                );
            };
        },
        dynamicField(state) {
            return (fieldType: string, type: any) => {
                const campaignDataStore = useCampaignDataStore();
                return (
                    state.dynamicFieldList.find(
                        (field: any) => field.type === fieldType
                    ) ?? null
                );
            };
        },
    },
    actions: {
        /**
         * Code upload
         *
         * @returns
         */
        async codeUpload(codeUploadData: any) {
            const PurchasedPromotionProductStore =
                usePurchasedPromotionProductStore();

            let rawImages, promises: Array<Promise<any>>, images;

            if (
                this.hasDynamicField("receipt-image") &&
                this.dynamicField("receipt-image")?.validation.includes(
                    "required"
                ) &&
                !this.receiptsImagesRaw
            ) {
                return;
            } else {
                if (
                    this.dynamicField("receipt-image")?.validation.includes(
                        "required"
                    )
                ) {
                    promises = [];
                    this.receiptsImagesRaw.forEach((formDataPart: any) => {
                        promises.push(this.uploadImage(formDataPart));
                    });
                    rawImages = await Promise.all(promises);
                    images = rawImages.map((res) => {
                        return res.file.filename;
                    });
                    console.log("images", images);
                    this.dynamicForm.receiptImage = images;
                }
            }

            const isApCode = Boolean(
                codeUploadData.type === EPurchaseType.AP_CODE
            );
            const isOnlinePurchase = Boolean(
                codeUploadData.type === EPurchaseType.ONLINE
            );
            const isCouponCode = Boolean(
                codeUploadData.type === EPurchaseType.COUPON_CODE
            );

            const selectedPromotions: any[] = [];
            PurchasedPromotionProductStore.selectedPromoProducts.forEach(
                (item: any) => {
                    selectedPromotions.push({
                        id: item.id,
                        quantity: item.quantity,
                    });
                }
            );

            const timeOfPurchase =
                codeUploadData.timeOfPurchase.substring(0, 2) +
                ":" +
                codeUploadData.timeOfPurchase.substring(
                    3,
                    codeUploadData.timeOfPurchase.length
                );

            const payload: { [key: string]: any } = {
                firstName: codeUploadData.firstName,
                lastName: codeUploadData.lastName,
                email: codeUploadData.email,
                placeOfPurchase: codeUploadData.placeOfPurchase,
                timeOfPurchase: isApCode
                    ? moment(
                        `${codeUploadData.dateOfPurchase} ${timeOfPurchase}`
                    ).format("YYYY-MM-DD HH:mm:ss")
                    : isOnlinePurchase
                        ? moment(`${codeUploadData.dateOfPurchase}`).format(
                            "YYYY-MM-DD"
                        )
                        : "",
                type: codeUploadData.type,
                code: isApCode
                    ? codeUploadData.apCode
                    : isCouponCode
                        ? codeUploadData.couponCode
                        : "",
                edmSubscription: Number(codeUploadData.marketing),
                receiptNumber: !isOnlinePurchase
                    ? codeUploadData.receiptNumber
                    : "",
                invoiceNumber: isOnlinePurchase
                    ? codeUploadData.invoiceNumber
                    : "",
                transactionNumber: !isOnlinePurchase
                    ? codeUploadData.transactionNumber
                    : "",
                navCode: !isOnlinePurchase ? codeUploadData.navCode : "",
                promotionIds: selectedPromotions,
                receiptImage: this.dynamicForm.receiptImage ?? [],
                dynamicFields: {
                    weeklyPrize: codeUploadData.weeklyPrize,
                },

            };

            if (this.userId) {
                payload["userId"] = this.userId;
            }

            const campaignDataStore = useCampaignDataStore();

            return await useApiFetch(
                `endusermanagerms/code/upload-v2/${campaignDataStore.campaignUUId}`,
                {
                    key:
                        "code-upload" +
                        campaignDataStore.campaignUUId +
                        "/" +
                        this.userId +
                        "/" +
                        Math.random(),
                    method: "POST",
                    body: payload,
                }
            );
        },
        async updateReceipts(receipts: any) {
            this.receiptsImagesRaw = receipts;
        },
        async uploadImage(imageFile: any) {
            const campaignDataStore = useCampaignDataStore();
            const campaignUuid = await campaignDataStore.obtainCampaignUUId();
            const campaignId = await campaignDataStore.obtainCampaignId();

            const formData = new FormData();

            formData.append("file", imageFile);
            formData.append("public", 0 as any);
            formData.append("campaignId", campaignId);
            formData.append("campaignUuid", campaignUuid);
            //Only for szentkirályi
            //if (campaignUuid == "cca63d5c-c47c-45be-8a3f-3c98e0037b7f") {
            //    const { data, error } = await useDirectApiFetch(
            //        `/imagemanagerms/upload`,
            //        {
            //            key: "receipt-image-upload" + Math.random(),
            //            method: "POST",
            //            body: formData,
            //        }
            //    );
            //
            //    return Promise.resolve(data.value);
            //} else {
            const { data, error } = await useApiFetch(
                `/imagemanagerms/upload`,
                {
                    key: "receipt-image-upload" + Math.random(),
                    method: "POST",
                    body: formData,
                }
            );

            return Promise.resolve(data.value);
            //}
        },
        async getDynamicFields() {
            const campaignDataStore = useCampaignDataStore();

            const { data, error } = await useApiFetch(
                `/drawms/dynamic-field/${campaignDataStore.campaignUUId}`,
                {
                    key: "dynamic-fields" + campaignDataStore.campaignUUId,
                    method: "GET",
                }
            );
            console.log("getDynamicFields:-----", data.value, error.value);
            if (data.value != null && data.value.dynamicFields) {
                this.dynamicFieldList = data.value.dynamicFields;
                this.createDynamicFieldFormItems();
            }
        },
        createDynamicFieldFormItems() {
            const typeCounters: any = {};

            const toCamelCase = (formItemName: string) => {
                return formItemName.replace(/-./g, (match) =>
                    match.charAt(1).toUpperCase()
                );
            };

            this.dynamicFieldList.forEach((formItem: any) => {
                const type = toCamelCase(formItem.type);

                if (!typeCounters[type]) {
                    typeCounters[type] = 0;
                }

                const key =
                    typeCounters[type] === 0
                        ? type
                        : `${type}${typeCounters[type]}`;

                if (type === "receiptImage" || type === "purchasedProducts") {
                    this.dynamicForm[key] = [];
                } else {
                    this.dynamicForm[key] = "";
                }

                typeCounters[type]++;
            });

            return this.dynamicForm;
        },
    },
});
