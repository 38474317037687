<template>
    <section>
        <div :style="backgroundStyle">
            <div class="container">
                <div class="row">
                    <div class="col-12 py-3 text-center">
                        <div class="py-5 px-4">
                            <h2 class="fw-900 mb-4" :style="sectionTitleStyle" v-if="section.sectionTitleRaw"
                                v-html="section.sectionTitleRaw"></h2>
                            <div class="d-flex flex-column align-items-center justify-content-center">
                                <div class="fw-900" :style="prizeNameStyle" v-if="shippingFromStore.prize">
                                    {{ shippingFromStore.prize.name }}
                                    <!-- {{ prize.name }} -->
                                </div>
                                <!-- <img
                                    class="img-fluid mt-4"
                                    :src="`${mediaURL}${prize.imageUrl}`"
                                    alt=""
                                /> -->

                                <img v-if="shippingFromStore.prize" :style="prizeImageStyle" class="img-fluid mt-4" :src="shippingFromStore.prize.imageUrl
                                        ? useMediaUrl(shippingFromStore.prize.imageUrl)
                                        : useCdnUrl('/images/150x150-image-placeholder.png')
                                    " alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Prizific page builder tool -->
        <client-only>
            <PrizificPageBuilderTool v-if="editable" :section="section" />
        </client-only>
        <!-- Prizific page builder tool end -->
    </section>
</template>

<script setup lang="ts">
import { getStyle } from "../../composables/styleHelper";
import { useShippingFromStore } from "@/store/components/shippingFrom/shippingFrom";

const shippingFromStore = useShippingFromStore();

interface Props {
    section: any;
    editable: boolean;
}

/**
 * Define props
 */
const props = defineProps<Props>();

const runtime = useRuntimeConfig();
// Computed form and form items style
const backgroundStyle = computed(() => {
    return getStyle(props.section.style, "yourPrize");
});
const sectionTitleStyle = computed(() => {
    return getStyle(props.section.style, "yourPrizeTitle");
});
const prizeNameStyle = computed(() => {
    return getStyle(props.section.style, "yourPrizeName");
});
const prizeImageStyle = computed(() => {
    return getStyle(props.section.style, "yourPrizeImage");
});
</script>

<style lang="scss"></style>
