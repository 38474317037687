<template>
    <div
        class="d-none d-lg-flex align-items-center align-items-lg-end justify-content-center my-2 ms-4"
    >
        <client-only>
            <el-dropdown
                trigger="click"
                @command="handleCommand"
                v-if="isAuthenticated"
            >
                <el-button class="my-profile-dropdown" plain>
                    <el-icon class="el-icon-user me-2">
                        <UserFilled />
                    </el-icon>
                    {{ userFullname }}
                    <el-icon class="el-icon--right">
                        <ArrowDownBold />
                    </el-icon>
                </el-button>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item command="uploaded-codes" v-if="campaignDataStore.campaignUUId != '118ef046-ee9f-4d37-9062-7788cd336c2d'">
                            Feltöltött kódjaim
                        </el-dropdown-item>
                        <el-dropdown-item command="profile">
                            Profil módosítás
                        </el-dropdown-item>
                        <el-dropdown-item command="change-password">
                            Jelszó módosítás
                        </el-dropdown-item>
                        <el-dropdown-item command="logout">
                            Kijelentkezés
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
        </client-only>
    </div>
</template>

<script setup lang="ts">
import {
    ElDropdown,
    ElDropdownMenu,
    ElDropdownItem,
    ElButton,
    ElIcon,
} from "element-plus";
import { ArrowDownBold, UserFilled } from "@element-plus/icons-vue";
import { useCampaignDataStore } from "@/store/campaign/campaignData";
import { useAuthStore } from "@/store/user/auth";
import { storeToRefs } from "pinia";

const authStore = useAuthStore();
const { isAuthenticated } = storeToRefs(authStore);
const campaignDataStore: any = useCampaignDataStore();

const router = useRouter();
const runtimeConfig = useRuntimeConfig();

const userFullname = computed((): string => {
    return `${authStore.user?.lastname} ${authStore.user?.firstname}`;
});

/**
 * Handle clicked dropdown item
 * @param command
 */
async function handleCommand(command: string) {
    if (command === "uploaded-codes") {
        if (campaignDataStore.isHiveConnection) {
            window.location.href = authStore.hiveUrl(
                `/uploaded-codes?redirectOrigin=${window.location.href}`
            );
        } else if (isAuthenticated) {
            router.push({
                path: useDynamicLink("/feltoltott-kodjaim"),
            });
        }
    } else if (command === "profile") {
        if (campaignDataStore.isHiveConnection) {
            window.location.href = authStore.hiveUrl(
                `/profile?redirectOrigin=${window.location.href}`
            );
        } else if (isAuthenticated) {
            router.push({
                path: useDynamicLink("/profil-modositas"),
            });
        }
    } else if (command === "edit-shipping-details") {
        if (campaignDataStore.isHiveConnection) {
            window.location.href = authStore.hiveUrl(
                `/szallitasi-adatok?redirectOrigin=${window.location.href}`
            );
        } else if (isAuthenticated) {
            router.push({
                path: useDynamicLink("/szallitasi-adatok-modositasa"),
            });
        }
    } else if (command === "change-password") {
        if (campaignDataStore.isHiveConnection) {
            window.location.href = authStore.hiveUrl(
                `/change-password?redirectOrigin=${window.location.href}`
            );
        } else if (isAuthenticated) {
            router.push({
                path: useDynamicLink("/jelszo-modositas"),
            });
        }
    } else if (command === "logout") {
        await authStore.logOut().then(() => {
            // location.reload();
            router.push({
                path: useDynamicLink(""),
            });
        });
    }
}
</script>

<style lang="scss">
.my-profile-dropdown {
    border-radius: 10px;
    color: #000000ff;
    border-color: black;
    border-width: 2px;
    padding: 12px 24px !important;
    font-weight: 600 !important;
    background-color: white;

    .el-icon-user,
    .el-icon-arrow-down {
        font-weight: bold;
    }

    &:hover,
    &:focus {
        color: black !important;
        border-color: black !important;
        background-color: white !important;
    }
}

.el-dropdown-menu__item {
    color: black;
    font-weight: 600;
}

.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
    color: #000000ff !important;
    background-color: white !important;
}
</style>
