<template>
    <div :style="codeUploadFormStyle">
        <el-form id="codeUploadForm" ref="codeUploadFormRef" :style="codeUploadFormMergedStyle" label-position="top"
            :model="codeUploadForm" :rules="rules" :scroll-to-error="true" @keydown.enter.prevent="submitCodeUploadForm"
            status-icon>
            <!-- How to buy -->
            <el-form-item prop="type" :label="inputHowToPurchaseTexts?.label">
                <ToggleButton :buttons="howToBuyToggleButton" v-model="codeUploadForm.type" />
            </el-form-item>
            <!-- How to buy end -->

            <!-- AP code -->
            <el-form-item v-if="showFormItem('apCode')" ref="apCodeFormItemRef" prop="apCode"
                :label="inputApCodeTexts?.label">
                <ApCodeInput ref="apCodeInput" v-model="codeUploadForm.apCode" @focus="focusedInput = $event" />
            </el-form-item>
            <!-- AP code end -->

            <div class="row">
                <div class="col-12 col-md-8 col-lg-6 col-xxl-8">
                    <!-- Purchase date -->
                    <el-form-item v-if="showFormItem('dateOfPurchase')" ref="dateOfPurchaseFormItemRef"
                        prop="dateOfPurchase" :label="inputPurchaseDateApCodeTexts?.label">
                        <client-only>
                            <ApCodeDatePicker :campaignStartDate="campaignDataStore.campaignData
                                ?.campaignStart
                                " :campaignEndDate="campaignDataStore.campaignData?.campaignEnd
                                    " v-model="codeUploadForm.dateOfPurchase" @focus="focusedInput = 'date'" />
                        </client-only>
                    </el-form-item>
                    <!-- Purchase date end -->
                </div>
                <div class="col-12 col-md-4 col-lg-6 col-xxl-4">
                    <!-- Purchase time -->
                    <el-form-item v-if="showFormItem('timeOfPurchase')" ref="timeOfPurchaseFormItemRef"
                        prop="timeOfPurchase" :label="inputPurchaseTimeTexts?.label">
                        <TimeOfPurchase v-model="codeUploadForm.timeOfPurchase" @focus="focusedInput = 'time'" />
                        <el-input ref="timeInput" class="time-mask" inputmode="numeric" maxlength="5"
                            :placeholder="inputPurchaseTimeTexts?.placeholder"
                            v-model="codeUploadForm.timeOfPurchase"></el-input>
                    </el-form-item>
                    <!-- Purchase time end -->
                </div>
            </div>

            <!-- Receipt number -->
            <el-form-item v-if="showFormItem('receiptNumber')" ref="receiptNumberFormItemRef" prop="receiptNumber"
                :label="inputReceiptNumberTexts?.label">
                <el-input ref="receiptNumber" type="text" :placeholder="inputReceiptNumberTexts?.placeholder"
                    @focus="focusedInput = 'receipt-number'" @keydown.tab="promoProductsDialog = true"
                    v-model="codeUploadForm.receiptNumber"></el-input>
            </el-form-item>
            <!-- Receipt number end -->

            <!-- Invoice number -->
            <el-form-item v-if="showFormItem('invoiceNumber')" ref="invoiceFormItemRef" prop="invoiceNumber"
                label="Számlaszám">
                <el-input ref="invoiceNumber" type="text" placeholder="Add meg a számlaszámot"
                    @focus="focusedInput = 'invoice-number'" v-model="codeUploadForm.invoiceNumber"></el-input>
            </el-form-item>
            <!-- Invoice number end -->

            <!-- NAV code -->
            <!-- <el-form-item
                v-if="showFormItem('navCode')"
                ref="navCodeFormItemRef"
                prop="navCode"
                label="Nav kód"
            >
                <el-input
                    maxlength="5"
                    ref="navCode"
                    type="text"
                    placeholder="Add meg a NAV kódot"
                    @focus="focusedInput = 'nav-code'"
                    v-model="codeUploadForm.navCode"
                ></el-input>
            </el-form-item> -->
            <!-- NAV code end -->

            <!-- Lastname -->
            <el-form-item v-if="showFormItem('lastName')" ref="lastNameFormItemRef" prop="lastName"
                :label="inputLastNameTexts?.label">
                <el-input ref="lastName" type="text" v-model="codeUploadForm.lastName"></el-input>
            </el-form-item>
            <!-- Lastname end -->

            <!-- Firstname -->
            <el-form-item v-if="showFormItem('firstName')" ref="firstNameFormItemRef" prop="firstName"
                :label="inputFirstNameTexts?.label">
                <el-input ref="firstName" type="text" v-model="codeUploadForm.firstName"></el-input>
            </el-form-item>
            <!-- Firstname end -->

            <!-- Email address -->
            <el-form-item v-if="showFormItem('email')" ref="emailFormItemRef" prop="email"
                :label="inputEmailTexts?.label">
                <el-input ref="email" type="email" v-model="codeUploadForm.email"></el-input>
            </el-form-item>
            <!-- Email address end -->

            <!-- Purchased promotion product -->
            <el-form-item v-if="
                showFormItem('promotionProductsId') &&
                codeUploadStore.hasDynamicField('purchased-products')
            " ref="promotionProductsFormItemRef" prop="promotionProductsId"
                :label="inputPurchasedPromotionPorductTexts?.label">
                <client-only>
                    <PurchasedPromotionProduct :placeholder="inputPurchasedPromotionPorductTexts?.placeholder ??
                        ''
                        " v-model="codeUploadForm.promotionProductsId" @click="promoProductsDialog = true"
                        :component="props.component[0]?.getComponents()[5]" />
                </client-only>
            </el-form-item>
            <!-- Purchased promotion product end -->

            <!-- Transaction number -->
            <el-form-item v-if="showFormItem('transactionNumber')" ref="transactionNumberFormItemRef"
                prop="transactionNumber" label="Tranzakciószám">
                <el-input ref="transactionNumber" type="text" placeholder="Add meg a tranzakciószámot (pl. 1234567890)"
                    @focus="focusedInput = 'transaction-number'" v-model="codeUploadForm.transactionNumber"></el-input>
            </el-form-item>
            <!-- Transaction number end -->



            <ClientOnly v-if="
                showFormItem('placeOfPurchaseOnline') &&

                codeUploadStore.hasDynamicField('place-of-purchase-online')
            ">
                <el-form-item ref="placeOfPurchaseFormItemRef" prop="placeOfPurchase" :label="codeUploadStore.dynamicField('place-of-purchase-online').name
                    ">
                    <el-select class="w-100" filterable :placeholder="inputPlaceOfPurchaseTexts?.placeholder"
                        v-model="codeUploadForm.placeOfPurchase">
                        <el-option v-for="option in codeUploadStore.dynamicField(
                            'place-of-purchase-online'
                        )?.options" :key="option" :label="option" :value="option">
                        </el-option>
                    </el-select>

                </el-form-item>
            </ClientOnly>
            <!-- Place of purchase -->
            <ClientOnly v-if="
                showFormItem('placeOfPurchase') &&
                codeUploadStore.hasDynamicField('place-of-purchase')
            ">
                <el-form-item ref="placeOfPurchaseFormItemRef" prop="placeOfPurchase" :label="codeUploadStore.dynamicField('place-of-purchase').name
                    ">
                    <el-select class="w-100" filterable :placeholder="inputPlaceOfPurchaseTexts?.placeholder"
                        v-model="codeUploadForm.placeOfPurchase">
                        <el-option v-for="option in codeUploadStore.dynamicField(
                            'place-of-purchase'
                        )?.options" :key="option" :label="option" :value="option">
                        </el-option>
                    </el-select>

                </el-form-item>
            </ClientOnly>



            <!-- Place of purchase end -->
            <ClientOnly v-if="showFormItem('weeklyPrize')">
                <el-form-item ref="dynamicFieldsWeeklyPrizeRef" label="Heti nyeremény" prop="weeklyPrize">
                    <el-select class="w-100" v-model="codeUploadForm.weeklyPrize">
                        <el-option label="Everdure Kiln pizzasütő" value="Everdure Kiln pizzasütő"></el-option>
                        <el-option label="Samsung LED 50AU7092 125cm TV"
                            value="Samsung LED 50AU7092 125cm TV"></el-option>
                    </el-select>
                </el-form-item>
            </ClientOnly>

            <el-form-item v-if="
                showFormItem('receiptImage') &&
                codeUploadStore.hasDynamicField('receipt-image')
            " ref="receiptImageFormItemRef" class="receipt-image" prop="receiptImage"
                :label="codeUploadStore.dynamicField('receipt-image').name">
                <div v-if="hasFile" class="row">
                    <div v-for="(
                            fileUrl, index
                        ) in recipeUpload.fileUrlsWithTypes" :key="index"
                        class="col-xs-12 col-lg-4 prizific-file mt-3 mb-3">
                        <img v-if="fileUrl.type == 'image'" :src="fileUrl.url" alt="" />

                        <div @click="handleRemove(index)" class="prizific-file-input-clear-icon">
                            <el-icon class="el-icon-minus" :size="24">
                                <Minus />
                            </el-icon>
                        </div>
                    </div>
                </div>
                <div class="prizific-file-input mt-2">
                    <div :class="[
                        'el-upload-list__item-thumbnail ',
                        {
                            'no-thumbnail': !recipeUpload.fileUrlsWithTypes,
                        },
                        { disabled: disabledFileUploader },
                    ]">
                        <span class="no-thumbnail-icon">
                            <el-icon :size="20">
                                <Camera />
                            </el-icon>
                        </span>
                        <div class="prizific-file-input-label-title py-2 px-3">
                            Blokk képének feltöltése
                        </div>
                    </div>

                    <!-- Texts -->
                    <label :class="[
                        'prizific-file-input-label',
                        { disabled: disabledFileUploader },
                    ]">
                        <input ref="fileInput" type="file" @change="onFileChange"
                            accept="image/png, image/jpeg, application/pdf" multiple :disabled="disabledFileUploader" />
                    </label>
                    <!-- Texts end-->
                </div>
            </el-form-item>

            <!-- Coupon Code -->
            <el-form-item v-if="showFormItem('couponCode')" ref="couponCodeFormItemRef" prop="couponCode"
                label="Extra kód">
                <ExtraCodeInput ref="couponCodeInput" type="text" placeholder="Add meg az extra kódot"
                    v-model="codeUploadForm.couponCode" @focus="focusedInput = $event" />
                <!-- <el-input ref="couponCodeInput" type="text" placeholder="Add meg az extra kódot"
                    v-model="codeUploadForm.couponCode" @focus="focusedInput = $event"></el-input> -->
            </el-form-item>
            <!-- Coupon Code end -->

            <!-- Privacy Policy checkbox -->
            <el-form-item v-if="showFormItem('privacyPolicy')" ref="privacyPolicyFormItemRef" prop="privacyPolicy"
                class="mt-3 mb-4">
                <el-checkbox v-model="codeUploadForm.privacyPolicy">
                    <!-- Elolvastam és elfogadom az
                    <a :href="useMediaUrl(campaignDataStore.campaignData.privacyPolicyPdfFile)
                        " target="_blank">adatvédelmi nyilatkozatot</a>
                    és a
                    <a :href="useMediaUrl(campaignDataStore.campaignData.rulesPdfFile)
                        " target="_blank">játékszabályokat</a>
                    és elmúltam 18 éves. -->
                    {{ checkboxPrivaciPolicyTexts?.label }}
                </el-checkbox>
            </el-form-item>
            <!-- Privacy Policy checkbox end -->

            <div class="text-center">
                <el-button :class="[
                    'submit-btn',
                    {
                        'is-disabled':
                            codeUploadForm.submitButtonClicked ||
                            !codeUploadFormIsValid,
                    },
                ]" @click="submitCodeUploadForm" :loading="codeUploadForm.submitButtonClicked">
                    Kódfeltöltés
                </el-button>
            </div>
        </el-form>

        <!-- Dialogs -->
        <!-- Purchased promo products dialog -->
        <PurchasedPromotionProductDialog :show="promoProductsDialog"
            :title="inputPurchasedPromotionPorductTexts?.label ?? ''" :description="inputPurchasedPromotionPorductTexts?.placeholder ?? ''
                " @close="promoProductsDialog = false" :component="component" />
        <!-- Purchased promo products dialog end-->
        <!-- Successful code upload Dialog -->
        <Dialog :show="responseDialogs.successfulCodeUploadDialog" :title="successfulCodeUploadModal?.title ?? ''"
            :description="successfulCodeUploadModal?.description"
            :footerBtnText="successfulCodeUploadModal?.buttonRight ?? ''" :component="component[1]"
            @closed="responseDialogs.successfulCodeUploadDialog = false" @btnClicked="resetFields" />
        <!-- Successful code upload Dialog end -->
        <!-- Code Not Win Dialog -->
        <Dialog :show="responseDialogs.apCodeNotWinDialog" :title="apCodeNotWinModal?.title"
            :description="apCodeNotWinModal?.description" :footerBtnText="apCodeNotWinModal?.rightButton"
            :component="component[1]" @closed="responseDialogs.apCodeNotWinDialog = false" @btnClicked="resetFields" />
        <!-- Code Not Win Dialog end -->
        <!-- Ap code conflict Dialog -->
        <Dialog :show="errorDialogs.apCodeConflictDialog" :title="apCodeConflictModal?.title ?? ''"
            :description="apCodeConflictModal?.description" :footerBtnText="apCodeConflictModal?.buttonRight ?? ''"
            :component="component[1]" @closed="errorDialogs.apCodeConflictDialog = false" @btnClicked="resetFields" />
        <!-- Ap code conflict Dialog end -->
        <!-- Ap code exhousted Dialog -->
        <Dialog :show="errorDialogs.aPCodeExhoustedDialog" :title="apCodeExhoustedModal?.title"
            :description="apCodeExhoustedModal?.description" :footerBtnText="apCodeExhoustedModal?.buttonRight ?? ''"
            :component="component[1]" @closed="errorDialogs.aPCodeExhoustedDialog = false" @btnClicked="resetFields" />
        <!-- Ap code exhousted Dialog end -->
        <!-- Ap code short time gate Dialog -->
        <Dialog :show="errorDialogs.aPCodeShortTimeGateDialog" :title="apCodeShortTimeGateModal?.title"
            :description="apCodeShortTimeGateModal?.description" :footerBtnText="apCodeShortTimeGateModal?.buttonRight"
            :component="component[1]" @closed="errorDialogs.aPCodeShortTimeGateDialog = false"
            @btnClicked="resetFields" />
        <!-- Ap code short time gate Dialog end -->
        <!-- Ap code conflict itself Dialog -->
        <Dialog :show="errorDialogs.aPCodeConflictItselfDialog" :title="apCodeConflictItselfModal?.title ?? ''"
            :description="apCodeConflictItselfModal?.description"
            :footerBtnText="apCodeConflictItselfModal?.buttonRight ?? ''" :component="component[1]"
            @closed="errorDialogs.aPCodeConflictItselfDialog = false" @btnClicked="resetFields" />
        <!-- Ap code conflict itself Dialog end -->
        <!-- Coupon code not exist Dialog -->
        <Dialog :show="errorDialogs.couponCodeNotExistDialog" :title="couponCodeNotExistModal?.title"
            :description="couponCodeNotExistModal?.description"
            :footerBtnText="couponCodeNotExistModal?.buttonRight ?? ''" :component="component[1]"
            @closed="errorDialogs.couponCodeNotExistDialog = false" @btnClicked="resetFields" />
        <!-- Coupon code not exist Dialog end -->
        <!-- Coupon code conflict Dialog -->
        <Dialog :show="errorDialogs.couponCodeConflictDialog" :title="couponCodeConflictModal?.title"
            :description="couponCodeConflictModal?.description"
            :footerBtnText="couponCodeConflictModal?.buttonRight ?? ''" :component="component[1]"
            @closed="errorDialogs.couponCodeConflictDialog = false" @btnClicked="resetFields" />
        <!-- Coupon code conflict Dialog end -->
        <!-- Coupon code conflict itself Dialog -->
        <Dialog :show="errorDialogs.couponCodeConflictItselfDialog" :title="couponCodeConflictItselfModal?.title"
            :description="couponCodeConflictItselfModal?.description"
            :footerBtnText="couponCodeConflictItselfModal?.buttonRight ?? ''" :component="component[1]"
            @closed="errorDialogs.couponCodeConflictItselfDialog = false" @btnClicked="resetFields" />
        <!-- Coupon code conflict itself Dialog end -->
        <!-- User has not been activated Dialog -->
        <Dialog :show="errorDialogs.notActivatedUserDialog" title="Kérjük, aktiváld a regisztrációdat"
            description="Kérjük, aktiváld az e-mail címedre küldött linkre kattintva." footerBtnText="Bezárás"
            :component="component[1]" @closed="errorDialogs.notActivatedUserDialog = false" @btnClicked="resetFields" />
        <!-- User has not been activated Dialog end -->
        <!-- Dialogs end -->
    </div>
</template>

<script setup lang="ts">
import moment from "moment";
import { useCampaignDataStore } from "@/store/campaign/campaignData";
import { useCodeUploadStore } from "@/store/components/codeUpload/codeUpload";
import { getFormItemText } from "../../composables/textHelper";
import {
    ElForm,
    ElFormItem,
    ElInput,
    ElSelect,
    ElOption,
    FormItemInstance,
} from "element-plus";
import type { FormInstance, FormRules } from "element-plus";
import ApCodeDatePicker from "../APCodeDatePicker/ApCodeDatePicker.vue";
import ApCodeInput from "../APCodeInput/ApCodeInput.vue";
import { ECodeUploadFormItem } from "~/enums/PrizificPageBuilder/ECodeUploadFormItem";
import { EProperty } from "~/enums/PrizificPageBuilder/EProperty";
import { EPurchaseType } from "~/enums/Campaign/EPurchaseType";
import { ECodeUploadErrorType } from "~/enums/Campaign/ECodeUploadErrorType";
import { usePurchasedPromotionProductStore } from "@/store/components/purchasedPromotionProduct/purchasedPromotionProduct";
//import { code } from "ionicons/icons";
import { EModal } from "~~/enums/PrizificPageBuilder/EModal";
//import { CodeUploadFormRules } from "./CodeUploadFormRules";
//import { without } from "lodash";
//import { validate } from "@babel/types";
import { Camera, Minus } from "@element-plus/icons-vue";
import { useShippingFromStore } from "~~/store/components/shippingFrom/shippingFrom";

const router = useRouter();

//const runtime = useRuntimeConfig();

const campaignDataStore = useCampaignDataStore();
const codeUploadStore = useCodeUploadStore();
//const shippingFormStore = useShippingFromStore();
const PurchasedPromotionProductStore = usePurchasedPromotionProductStore();

//THIS: have to be investigated
await codeUploadStore.getDynamicFields();

onMounted(async () => {
    await codeUploadStore.getDynamicFields();
});

interface Props {
    component: Array<any>;
}

/**
 * Define props
 */
const props = defineProps<Props>();

/**
 * Define emits
 */
const emit = defineEmits(["highlight-change"]);

const codeUploadComponent = props.component[0];
const feedbackModalsComponent = props.component[1];

const codeUploadFormRef = ref<FormInstance>();
const firstNameFormItemRef = ref<FormItemInstance>();
const lastNameFormItemRef = ref<FormItemInstance>();
const apCodeFormItemRef = ref<FormItemInstance>();
const dateOfPurchaseFormItemRef = ref<FormItemInstance>();
const timeOfPurchaseFormItemRef = ref<FormItemInstance>();
const emailFormItemRef = ref<FormItemInstance>();
const receiptNumberFormItemRef = ref<FormItemInstance>();
const promotionProductsFormItemRef = ref<FormItemInstance>();
const invoiceFormItemRef = ref<FormItemInstance>();
const transactionNumberFormItemRef = ref<FormItemInstance>();
const navCodeFormItemRef = ref<FormItemInstance>();
const placeOfPurchaseFormItemRef = ref<FormItemInstance>();
const couponCodeFormItemRef = ref<FormItemInstance>();
const privacyPolicyFormItemRef = ref<FormItemInstance>();
const receiptImageFormItemRef = ref<FormItemInstance>();
const dynamicFieldsWeeklyPrizeRef = ref<FormItemInstance>();

const codeUploadForm = reactive({
    email: "",
    firstName: "",
    lastName: "",
    type: EPurchaseType.AP_CODE,
    apCode: "",
    couponCode: "",
    placeOfPurchase: "",
    dateOfPurchase: "",
    timeOfPurchase: "",
    promotionProductsId: [],
    navCode: "",
    receiptNumber: "",
    transactionNumber: "",
    invoiceNumber: "",
    marketing: false,
    privacyPolicy: false,
    submitButtonClicked: false,
    submitButtonErrorMessageShow: false,
    apCodeReachedMaxLength: false,
    timeOfPurchaseReachedMaxLength: false,
    ...codeUploadStore.dynamicForm,
    weeklyPrize: "",
});

const validateTimeOfPurchase = (rule: any, value: any, callback: any) => {
    if (value === "") {
        codeUploadForm.timeOfPurchaseReachedMaxLength = false;
        callback(new Error("Kötelező mező"));
    } else if (value.length !== 5) {
        if (codeUploadForm.timeOfPurchaseReachedMaxLength) {
            callback(new Error("Valami nem stimmel kérjük javítsd"));
        }
    } else {
        codeUploadForm.timeOfPurchaseReachedMaxLength = true;
        callback();
    }
};

const validateReceiptImage = (rule: any, value: any, callback: any) => {
    if (receiptImageRequired.value) {
        if (
            codeUploadStore.receiptsImagesRaw == undefined ||
            codeUploadStore.receiptsImagesRaw?.length == 0
        ) {
            callback(new Error("Kötelező mező"));
        } else {
            callback();
        }
    } else {
        callback();
    }
};

const rules = reactive<FormRules>({
    lastName: [
        {
            required: true,
            message: "Kötelező mező!",
            trigger: ["blur", "change"],
        },
    ],
    firstName: [
        {
            required: true,
            message: "Kötelező mező!",
            trigger: ["blur", "change"],
        },
    ],
    email: [
        {
            required: true,
            message: "Kötelező mező!",
            trigger: ["blur", "change"],
        },
        {
            pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
            message: "Hiba az email címben!",
            trigger: ["blur", "change"],
        },
    ],
    receiptNumber: [
        {
            required: true,
            message: "Kötelező mező!",
            trigger: ["blur", "change"],
        },
    ],
    promotionProductsId: [
        {
            required:
                codeUploadStore
                    .dynamicField("purchased-products")
                    ?.validation.includes("required") ?? false,
            message: "Kötelező mező!",
            trigger: ["blur", "change"],
        },
    ],
    apCode: [
        {
            required: true,
            message: "Kötelező mező!",
            trigger: ["blur", "change"],
        },
        {
            min: 9,
            max: 9,
            message: "Kötelező mező!",
            trigger: "blur",
        },
    ],
    dateOfPurchase: [
        {
            required: true,
            message: "Kötelező mező!",
            trigger: ["blur", "change"],
        },
    ],
    timeOfPurchase: [{ validator: validateTimeOfPurchase, trigger: "change" }],
    receiptImage: [
        { validator: validateReceiptImage, trigger: ["blur", "change"] },
    ],
    placeOfPurchase: [
        {
            required:
                codeUploadStore
                    .dynamicField("place-of-purchase")
                    ?.validation.includes("required") ?? false,
            message: "Kötelező mező!",
            trigger: ["blur", "change"],
        },
    ],
    couponCode: [
        {
            required: true,
            message: "Kérlek, add meg az extra kódot",
            trigger: ["blur", "change"],
        },
        {
            min: 10,
            max: 10,
            message: "Kötelező mező!",
            trigger: "blur",
        },
    ],
    //navCode: [
    //    {
    //        required: true,
    //        message: "Kérjük, add meg a nav kódot",
    //        trigger: ["blur", "change"],
    //    },
    //],
    invoiceNumber: [
        {
            required: true,
            message: "Kötelező mező!",
            trigger: ["blur", "change"],
        },
    ],
    weeklyPrize: [
        {
            required:
                campaignDataStore.campaignUUId ==
                "cc601dc3-716a-4cb3-a994-14d3ec111761" ||
                campaignDataStore.campaignUUId ==
                "a46bdb28-0c9b-463b-a48e-b2e78acd71b8",
            message: "Kötelező mező!",
            trigger: ["blur", "change"],
        },
    ],
    //privacyPolicy: [
    //    {
    //        type: "enum",
    //        enum: ["true"],
    //        required: true,
    //        message: "Kötelező mező!",
    //        trigger: "change",
    //        transform: (value) => value.toString(),
    //    },
    //],
});

console.log("rules", rules);

const formItemsByPurchaseType = [
    {
        [EPurchaseType.AP_CODE]: {
            withRegistration: {
                formItems: [
                    "apCode",
                    "placeOfPurchase",
                    "dateOfPurchase",
                    "timeOfPurchase",
                    "receiptNumber",
                    "receiptImage",
                    "weeklyPrize",
                    //"navCode",
                    "promotionProductsId",
                    //"privacyPolicy",
                ],
            },
            withoutRegistration: {
                formItems: [
                    "email",
                    "firstName",
                    "lastName",
                    "apCode",
                    "placeOfPurchase",
                    "dateOfPurchase",
                    "timeOfPurchase",
                    "receiptNumber",
                    "receiptImage",
                    //"navCode",
                    //"promotionProductsId",
                    //"privacyPolicy",
                ],
            },
        },
    },
    {
        [EPurchaseType.ONLINE]: {
            withRegistration: {
                formItems: [
                    //"transactionNumber",
                    "invoiceNumber",
                    "dateOfPurchase",
                    "receiptImage",
                    "weeklyPrize",
                    //"timeOfPurchase",
                    //"promotionProductsId",
                    //"privacyPolicy",
                    "placeOfPurchase",
                    'placeOfPurchaseOnline',
                
                ],
            },
            withoutRegistration: {
                formItems: [
                    "transactionNumber",
                    "invoiceNumber",
                    "promotionProductsId",
                    "privacyPolicy",
                    "receiptImage",
                ],
            },
        },
    },
    {
        [EPurchaseType.COUPON_CODE]: {
            withRegistration: {
                formItems: ["couponCode", "weeklyPrize"],
            },
            withoutRegistration: {
                formItems: ["couponCode"],
            },
        },
    },
];

const focusedInput = ref("");
const howToBuyToggleButton = [
    {
        label: "Személyesen",
        value: EPurchaseType.AP_CODE,
    },
    {
        label: "Online",
        value: EPurchaseType.ONLINE,
    },
    //{
    //    label: "Extra kód",
    //    value: EPurchaseType.COUPON_CODE,
    //},
];

if (
    campaignDataStore.campaignUUId == "a46bdb28-0c9b-463b-a48e-b2e78acd71b8" ||
    campaignDataStore.campaignUUId == "cc601dc3-716a-4cb3-a994-14d3ec111761"
) {
    howToBuyToggleButton.push({
        label: "Sorsjegy kód",
        value: EPurchaseType.COUPON_CODE,
    });
}

const responseDialogs = reactive({
    apCodeNotWinDialog: false,
    successfulCodeUploadDialog: false,
});

const errorDialogs = reactive({
    apCodeConflictDialog: false,
    aPCodeExhoustedDialog: false,
    aPCodeShortTimeGateDialog: false,
    aPCodeConflictItselfDialog: false,
    couponCodeNotExistDialog: false,
    couponCodeConflictDialog: false,
    couponCodeConflictItselfDialog: false,
    notActivatedUserDialog: false,
});

const promoProductsDialog = ref(false);

/**
 * Handle form submit
 */
async function submitCodeUploadForm() {
    codeUploadForm.submitButtonClicked = true;

    await codeUploadFormRef.value?.validate(async (valid, fields) => {
        if (valid) {
            console.log("submit!");
            try {
                const { data, error } = await codeUploadStore.codeUpload(
                    codeUploadForm
                );

                if (
                    error.value &&
                    error.value.data &&
                    error.value.data.errorType
                ) {
                    codeUploadErrorHandle(error.value.data.errorType);
                    codeUploadForm.submitButtonClicked = false;
                } else if (error.value) {
                    throw error;
                }

                console.log("Winner URL", useDynamicLink("/nyertel/"));
                console.log("Data", data.value);

                if (
                    data.value.uploadedCode.winnerUuid !== undefined &&
                    data.value.uploadedCode.notifyImmediately
                ) {
                    if (
                        campaignDataStore.campaignUUId ==
                        "a46bdb28-0c9b-463b-a48e-b2e78acd71b8" ||
                        campaignDataStore.campaignUUId ==
                        "cc601dc3-716a-4cb3-a994-14d3ec111761"
                    ) {
                        router.push({
                            path: useDynamicLink(
                                "/sorsolas/" + data.value.uploadedCode.id
                            ),
                        });
                    } else {
                        router.push({
                            path: useDynamicLink(
                                "/nyertel/" + data.value.uploadedCode.winnerUuid
                            ),
                        });
                    }
                } else {
                    //Redirect anyway
                    if (
                        campaignDataStore.campaignUUId ==
                        "a46bdb28-0c9b-463b-a48e-b2e78acd71b8" ||
                        campaignDataStore.campaignUUId ==
                        "cc601dc3-716a-4cb3-a994-14d3ec111761"
                    ) {
                        router.push({
                            path: useDynamicLink(
                                "/sorsolas/" + data.value.uploadedCode.id
                            ),
                        });
                    } else {
                        responseDialogs.apCodeNotWinDialog = true;
                    }
                }

                codeUploadForm.submitButtonClicked = false;
            } catch (e) {
                console.error("Error while upload code", e);
                codeUploadForm.submitButtonClicked = false;
            }

            /*    .then((response) => {

                })
                .catch((error) => {
                    codeUploadErrorHandle(error.errorType);

                    codeUploadForm.submitButtonClicked = false;
                });*/
        } else {
            console.log("error submit!", fields);
            codeUploadForm.submitButtonClicked = false;
        }
    });
}

/**
 * Check code upload form is valid
 */
const codeUploadFormIsValid = computed(() => {
    let formIsValid = false;
    let fields: string[] = [];

    activeFormItems.value?.formItems.forEach((formItem: string) => {
        if (formItem == "apCode") {
            apCodeFormItemRef.value?.validateState !== undefined
                ? apCodeFormItemRef.value?.validateState
                : "unknown-" + formItem;
        } else if (formItem == "email") {
            fields.push(
                emailFormItemRef.value?.validateState !== undefined
                    ? emailFormItemRef.value?.validateState
                    : "unknown-" + formItem
            );
        } else if (formItem == "firstName") {
            fields.push(
                firstNameFormItemRef.value?.validateState !== undefined
                    ? firstNameFormItemRef.value?.validateState
                    : "unknown-" + formItem
            );
        } else if (formItem == "lastName") {
            fields.push(
                lastNameFormItemRef.value?.validateState !== undefined
                    ? lastNameFormItemRef.value?.validateState
                    : "unknown-" + formItem
            );
        } else if (formItem == "dateOfPurchase") {
            fields.push(
                dateOfPurchaseFormItemRef.value?.validateState !== undefined
                    ? dateOfPurchaseFormItemRef.value?.validateState
                    : "unknown-" + formItem
            );
        } else if (formItem == "timeOfPurchase") {
            fields.push(
                timeOfPurchaseFormItemRef.value?.validateState !== undefined
                    ? timeOfPurchaseFormItemRef.value?.validateState
                    : "unknown-" + formItem
            );
        } else if (formItem == "promotionProductsId") {
            if (purchasedProductsRequired.value) {
                fields.push(
                    promotionProductsFormItemRef.value?.validateState !==
                        undefined
                        ? promotionProductsFormItemRef.value?.validateState
                        : "unknown-" + formItem
                );
            }
        } else if (formItem == "receiptNumber") {
            fields.push(
                receiptNumberFormItemRef.value?.validateState !== undefined
                    ? receiptNumberFormItemRef.value?.validateState
                    : "unknown-" + formItem
            );
        } else if (formItem == "transactionNumber") {
            fields.push(
                transactionNumberFormItemRef.value?.validateState !== undefined
                    ? transactionNumberFormItemRef.value?.validateState
                    : "unknown-" + formItem
            );
        } else if (formItem == "invoiceNumber") {
            fields.push(
                invoiceFormItemRef.value?.validateState !== undefined
                    ? invoiceFormItemRef.value?.validateState
                    : "unknown-" + formItem
            );
        } else if (formItem == "couponCodeFormItemRef") {
            fields.push(
                couponCodeFormItemRef.value?.validateState !== undefined
                    ? couponCodeFormItemRef.value?.validateState
                    : "unknown-" + formItem
            );
        } else if (formItem == "privacyPolicy") {
            fields.push(
                privacyPolicyFormItemRef.value?.validateState !== undefined
                    ? privacyPolicyFormItemRef.value?.validateState
                    : "unknown-" + formItem
            );
        } else if (formItem == "receiptImage") {
            if (receiptImageRequired.value) {
                fields.push(
                    receiptImageFormItemRef.value?.validateState !== undefined
                        ? receiptImageFormItemRef.value?.validateState
                        : "unknown-" + formItem
                );
            }
        } else if (formItem == "placeOfPurchase") {
            if (placeOfPurchaseRequired.value) {
                fields.push(
                    placeOfPurchaseFormItemRef.value?.validateState !==
                        undefined
                        ? placeOfPurchaseFormItemRef.value?.validateState
                        : "unknown-" + formItem
                );
            }
        } else if (formItem == "weeklyPrize") {
            fields.push(
                dynamicFieldsWeeklyPrizeRef.value?.validateState !== undefined
                    ? dynamicFieldsWeeklyPrizeRef.value?.validateState
                    : "unknown-" + formItem
            );
        }
    });

    const errors = fields.filter((field: string) => {
        return field == "error" || field.includes("unknown-");
    });

    formIsValid = !Boolean(errors.length);

    //console.log(
    //    "codeUploadFormIsValid",
    //    formIsValid,
    //    activeFormItems.value,
    //    errors.length,
    //    errors
    //);

    return formIsValid;
});

/**
 * Is ap code
 * @returns {boolean}
 */
const isApCode = computed(() => {
    return codeUploadForm.type === EPurchaseType.AP_CODE;
});

/**
 * Is Online Purchase
 * @returns {boolean}
 */
const isOnlinePurchase = computed(() => {
    return codeUploadForm.type === EPurchaseType.ONLINE;
});

/**
 * Has coupon code
 * @returns {boolean}
 */
const isCouponCode = computed(() => {
    return codeUploadForm.type === EPurchaseType.COUPON_CODE;
});

/**
 * Active form items
 */
const activeFormItems = computed(() => {
    for (let purchaseType in EPurchaseType) {
        if (
            codeUploadForm.type ===
            EPurchaseType[purchaseType as keyof typeof EPurchaseType]
        ) {
            const selectedItem: any = formItemsByPurchaseType.find(
                (item) =>
                    item[
                    EPurchaseType[
                    purchaseType as keyof typeof EPurchaseType
                    ]
                    ]
            );

            if (campaignDataStore.isRegistrationRequired) {
                return selectedItem[
                    EPurchaseType[purchaseType as keyof typeof EPurchaseType]
                ].withRegistration;
            } else {
                return selectedItem[
                    EPurchaseType[purchaseType as keyof typeof EPurchaseType]
                ].withoutRegistration;
            }
        }
    }
});

function isStaroAndOnline() {
    return (campaignDataStore.campaignUUId ==
        "cc601dc3-716a-4cb3-a994-14d3ec111761" ||
        campaignDataStore.campaignUUId ==
        "a46bdb28-0c9b-463b-a48e-b2e78acd71b8") &&
        codeUploadForm.type == 'ONLINE';
}

function showFormItem(formItemName: string) {
    if (
        formItemName == "weeklyPrize" &&
        activeFormItems.value?.formItems?.includes(formItemName) &&
        (campaignDataStore.campaignUUId ==
            "cc601dc3-716a-4cb3-a994-14d3ec111761" ||
            campaignDataStore.campaignUUId ==
            "a46bdb28-0c9b-463b-a48e-b2e78acd71b8")
    ) {
        return true;
    } else if (formItemName == "weeklyPrize") {
        return false;
    }

    if (
        formItemName == "placeOfPurchaseOnline" &&
        activeFormItems.value?.formItems?.includes(formItemName) &&
        (campaignDataStore.campaignUUId ==
            "cc601dc3-716a-4cb3-a994-14d3ec111761" ||
            campaignDataStore.campaignUUId ==
            "a46bdb28-0c9b-463b-a48e-b2e78acd71b8")
    ) {
        console.log("placeOfPurchaseOnline", formItemName, codeUploadForm.type);
        if (formItemName == "placeOfPurchaseOnline" && codeUploadForm.type == 'ONLINE') {
            return true;
        }

        return false;
    }

    if (
        formItemName == "placeOfPurchase" &&
        activeFormItems.value?.formItems?.includes(formItemName) &&
        (campaignDataStore.campaignUUId ==
            "cc601dc3-716a-4cb3-a994-14d3ec111761" ||
            campaignDataStore.campaignUUId ==
            "a46bdb28-0c9b-463b-a48e-b2e78acd71b8")
    ) {
        console.log("placeOfPurchase", formItemName, codeUploadForm.type);
        if (formItemName == "placeOfPurchase" && codeUploadForm.type == 'AP_CODE') {
            return true;
        }

        return false;
    }




    return activeFormItems.value?.formItems?.includes(formItemName);
}

/**
 * Handle code upload errors
 */
function codeUploadErrorHandle(errorType: ECodeUploadErrorType) {
    if (errorType == ECodeUploadErrorType.AP_CODE_CONFLICT) {
        errorDialogs.apCodeConflictDialog = true;
    } else if (errorType == ECodeUploadErrorType.AP_CODE_EXHAUSTED) {
        errorDialogs.aPCodeExhoustedDialog = true;
    } else if (errorType == ECodeUploadErrorType.NOT_ACTIVATED_USER) {
        errorDialogs.notActivatedUserDialog = true;
    } else if (errorType == ECodeUploadErrorType.AP_CODE_SHORT_TIME_GATE) {
        errorDialogs.aPCodeShortTimeGateDialog = true;
    } else if (errorType == ECodeUploadErrorType.AP_CODE_CONFLICT_ITSELF) {
        errorDialogs.aPCodeConflictItselfDialog = true;
    } else if (errorType == ECodeUploadErrorType.COUPON_CODE_NOT_EXISTS) {
        errorDialogs.couponCodeNotExistDialog = true;
    } else if (errorType == ECodeUploadErrorType.COUPON_CODE_CONFLICT) {
        errorDialogs.couponCodeConflictDialog = true;
    } else if (errorType == ECodeUploadErrorType.COUPON_CODE_CONFLICT_ITSELF) {
        errorDialogs.couponCodeConflictItselfDialog = true;
    }
}

/**
 * Reset codeupload form fields and clear validation
 */
function resetFieldsAndClearValidation() {
    codeUploadForm.apCodeReachedMaxLength = false;
    codeUploadForm.timeOfPurchaseReachedMaxLength = false;
    codeUploadForm.dateOfPurchase = "";
    codeUploadForm.timeOfPurchase = "";
    codeUploadForm.receiptNumber = "";
    codeUploadForm.invoiceNumber = "";
    codeUploadForm.apCode = "";
    codeUploadForm.couponCode = "";
    codeUploadForm.navCode = "";
    codeUploadForm.promotionProductsId = [];
    PurchasedPromotionProductStore.selectedPromoProducts = [];
    PurchasedPromotionProductStore.selectedPromoProductsId = [];
    codeUploadForm.placeOfPurchase = "";
    codeUploadForm.weeklyPrize = "";
    resetReceiptFormItem();

    codeUploadFormRef.value?.clearValidate();
}

/**
 * Reset code upload form fields
 */
function resetFields() {
    const type = codeUploadForm.type;

    codeUploadForm.apCodeReachedMaxLength = false;
    codeUploadForm.timeOfPurchaseReachedMaxLength = false;
    codeUploadForm.apCode = "";
    codeUploadForm.timeOfPurchase = "";
    codeUploadForm.receiptNumber = "";
    codeUploadForm.invoiceNumber = "";
    codeUploadForm.navCode = "";
    codeUploadForm.weeklyPrize = "";
    codeUploadForm.promotionProductsId = [];
    PurchasedPromotionProductStore.selectedPromoProducts = [];
    PurchasedPromotionProductStore.selectedPromoProductsId = [];
    resetReceiptFormItem();
    codeUploadFormRef.value?.resetFields();
    codeUploadForm.type = type;
    responseDialogs.apCodeNotWinDialog = false;
}

const fileInput = ref();
const recipeUpload = reactive({
    fileUrlsWithTypes: [] as any,
    files: [] as any,
    formData: null as any,
    errorMessage: "",
});

/**
 * Disabled file uploader after 5 file
 * @returns {boolean}
 */
const disabledFileUploader = computed(() => {
    return recipeUpload.fileUrlsWithTypes.length == 5;
});

/**
 * Has any file
 * @returns {boolean}
 */
const hasFile = computed(() => {
    return recipeUpload.fileUrlsWithTypes.length;
});

const receiptImageRequired = computed(() => {
    return codeUploadStore
        .dynamicField("receipt-image")
        ?.validation.includes("required");
});

const placeOfPurchaseRequired = computed(() => {
    return codeUploadStore
        .dynamicField("place-of-purchase")
        ?.validation.includes("required");
});

const purchasedProductsRequired = computed(() => {
    return codeUploadStore
        .dynamicField("purchased-products")
        ?.validation.includes("required");
});

function resetReceiptFormItem() {
    codeUploadStore.updateReceipts([]);
    recipeUpload.fileUrlsWithTypes = [];
    codeUploadForm.receiptImage = [];
    recipeUpload.files = [];
    recipeUpload.formData = null;
}

/**
 * Handle input file when is change
 */
function onFileChange(event: any) {
    const eventFile = event.target.files;

    if (eventFile) {
        recipeUpload.files.push(...Array.from(eventFile));
        recipeUpload.fileUrlsWithTypes = [];
        recipeUpload.formData = new FormData();
        console.log("itt a foreach?");
        recipeUpload.files.forEach((file: any, index: number) => {
            if (
                file["type"] === "image/jpeg" ||
                file["type"] === "image/jpg" ||
                file["type"] === "image/png" ||
                file["type"] === "application/pdf"
            ) {
                if (recipeUpload.fileUrlsWithTypes.length + 1 <= 5) {
                    console.log("file", file);
                    recipeUpload.formData.append("file" + index, file);
                    let typeSplit = file["type"].split("/");

                    console.log("File type: ", typeSplit[0]);

                    recipeUpload.fileUrlsWithTypes.push({
                        type: typeSplit[0],
                        url: URL.createObjectURL(file),
                    });
                }
            } else {
                recipeUpload.errorMessage =
                    "A képek .jpg, .jpeg vagy .png formátumúak kell hogy legyenek, számla esetében pedig .pdf!";
            }
        });
        codeUploadStore.updateReceipts(recipeUpload.formData);
        codeUploadForm.receiptImage = recipeUpload.formData;
        receiptImageFormItemRef.value?.validate();
    }
}

/**
 * Remove selected file
 */
function handleRemove(index: any) {
    const fileList = new DataTransfer();
    recipeUpload.fileUrlsWithTypes.splice(index, 1);
    recipeUpload.files.splice(index, 1);
    recipeUpload.formData.delete(`file${index}`);

    if (recipeUpload.files?.length) {
        recipeUpload.files.forEach((item: File) => {
            const file = new File(["content"], item.name);
            fileList.items.add(file);
        });
    }

    fileInput.value.files = fileList.files;

    if (!recipeUpload.files.length) {
        recipeUpload.formData = null;
    }
    codeUploadStore.updateReceipts(recipeUpload.formData);
    codeUploadForm.receiptImage = recipeUpload.formData;
    receiptImageFormItemRef.value?.validate();
}

// Computed form and form items style
const codeUploadFormStyle = computed(() => {
    return getStyle(props.component[0].style, "codeUploadFormBackground");
});
const codeUploadFormItemLabelStyle = computed(() => {
    return getStyle(props.component[0].style, "codeUploadFormItemLabel");
});
const codeUploadFormItemInputStyle = computed(() => {
    return getStyle(props.component[0].style, "codeUploadFormItemInput");
});
const codeUploadFormItemInputPlaceholderStyle = computed(() => {
    return getStyle(
        props.component[0].style,
        "codeUploadFormItemInputPlaceholder"
    );
});
const codeUploadFormItemInputReceiptImageStyle = computed(() => {
    return getStyle(props.component[0].style, "codeUploadFormItemReceiptImage");
});
const codeUploadFormSubmitButtonStyle = computed(() => {
    return getStyle(props.component[0].style, "codeUploadFormSubmitButton");
});
const codeUploadFormSubmitButtonHoverStyle = computed(() => {
    return getStyle(
        props.component[0].style,
        "codeUploadFormSubmitButtonHover"
    );
});
const codeUploadFormItemToggleButtonStyle = computed(() => {
    return getStyle(props.component[0].style, "codeUploadFormItemToggleButton");
});
const codeUploadFormItemToggleButtonActiveStyle = computed(() => {
    return getStyle(
        props.component[0].style,
        "codeUploadFormItemToggleButtonActive"
    );
});

const codeUploadFormMergedStyle = computed(() => {
    return {
        "--toggle-button-color":
            codeUploadFormItemToggleButtonStyle.value[EProperty.COLOR],
        "--toggle-button-active-color":
            codeUploadFormItemToggleButtonActiveStyle.value[EProperty.COLOR],
        "--toggle-button-active-background-color":
            codeUploadFormItemToggleButtonActiveStyle.value[
            EProperty.BACKGROUND_COLOR
            ],
        "--toggle-button-border-color":
            codeUploadFormItemToggleButtonStyle.value[EProperty.BORDER_COLOR],
        "--toggle-button-background-color":
            codeUploadFormItemToggleButtonStyle.value[
            EProperty.BACKGROUND_COLOR
            ],
        "--toggle-button-border-radius":
            codeUploadFormItemToggleButtonStyle.value[EProperty.BORDER_RADIUS],
        "--code-upload-form-label-color":
            codeUploadFormItemLabelStyle.value[EProperty.COLOR],
        "--code-upload-form-font-size":
            codeUploadFormItemLabelStyle.value[EProperty.FONT_SIZE],
        "--code-upload-form-input-text-color":
            codeUploadFormItemInputStyle.value[EProperty.COLOR] ?? "#606266",
        "--code-upload-form-input-receipt-image-close-icon-color":
            codeUploadFormItemInputReceiptImageStyle.value[EProperty.COLOR] ??
            "black",
        "--code-upload-form-input-background-color":
            codeUploadFormItemInputStyle.value[EProperty.BACKGROUND_COLOR] ??
            "white",
        "--code-upload-form-input-placeholder-color":
            codeUploadFormItemInputPlaceholderStyle.value[EProperty.COLOR] ??
            "#c4c7cf",
        "--code-upload-form-input-border-radius":
            codeUploadFormItemInputStyle.value[EProperty.BORDER_RADIUS],
        "--code-upload-form-input-border-color":
            codeUploadFormItemInputStyle.value[EProperty.BORDER_COLOR],
        "--code-upload-form-input-border-width":
            codeUploadFormItemInputStyle.value[EProperty.BORDER_WIDTH],
        "--code-upload-form-input-padding":
            codeUploadFormItemInputStyle.value[EProperty.PADDING],
        "--code-upload-form-input-height":
            codeUploadFormItemInputStyle.value[EProperty.HEIGHT],
        "--code-upload-form-input-font-size":
            codeUploadFormItemInputStyle.value[EProperty.FONT_SIZE],
        "--code-upload-checkbox-label-color":
            codeUploadFormItemLabelStyle.value[EProperty.COLOR],
        "--code-upload-checkbox-label-bg-color":
            codeUploadFormItemLabelStyle.value[EProperty.COLOR],
        "--code-upload-form-submit-color":
            codeUploadFormSubmitButtonStyle.value[EProperty.COLOR],
        "--code-upload-form-submit-font-size":
            codeUploadFormSubmitButtonStyle.value[EProperty.FONT_SIZE],
        "--code-upload-form-submit-padding":
            codeUploadFormSubmitButtonStyle.value[EProperty.PADDING],
        "--code-upload-form-submit-background-color":
            codeUploadFormSubmitButtonStyle.value[EProperty.BACKGROUND_COLOR],
        "--code-upload-form-submit-border-width":
            codeUploadFormSubmitButtonStyle.value[EProperty.BORDER_WIDTH],
        "--code-upload-form-submit-border-color":
            codeUploadFormSubmitButtonStyle.value[EProperty.BORDER_COLOR],
        "--code-upload-form-submit-border-radius":
            codeUploadFormSubmitButtonStyle.value[EProperty.BORDER_RADIUS],
        "--code-upload-form-submit-color-hover":
            codeUploadFormSubmitButtonHoverStyle.value[EProperty.COLOR],
        "--code-upload-form-submit-background-color-hover":
            codeUploadFormSubmitButtonHoverStyle.value[
            EProperty.BACKGROUND_COLOR
            ],
        "--code-upload-form-submit-border-width-hover":
            codeUploadFormSubmitButtonHoverStyle.value[EProperty.BORDER_WIDTH],
        "--code-upload-form-submit-border-color-hover":
            codeUploadFormSubmitButtonHoverStyle.value[EProperty.BORDER_COLOR],
    };
});

// Computed form items texts
const inputHowToPurchaseTexts = computed(() => {
    return getFormItemText(
        codeUploadComponent,
        ECodeUploadFormItem.HOW_TO_PURCHASE
    );
});
const inputFirstNameTexts = computed(() => {
    return getFormItemText(codeUploadComponent, ECodeUploadFormItem.FIRST_NAME);
});
const inputLastNameTexts = computed(() => {
    return getFormItemText(codeUploadComponent, ECodeUploadFormItem.LAST_NAME);
});
const inputEmailTexts = computed(() => {
    return getFormItemText(codeUploadComponent, ECodeUploadFormItem.EMAIL);
});
const inputPurchasedPromotionPorductTexts = computed(() => {
    return getFormItemText(
        codeUploadComponent,
        ECodeUploadFormItem.PURCHASED_PROMOTION_PRODUCT
    );
});
const inputApCodeTexts = computed(() => {
    return getFormItemText(codeUploadComponent, ECodeUploadFormItem.AP_CODE);
});
const inputPurchaseDateApCodeTexts = computed(() => {
    return getFormItemText(
        codeUploadComponent,
        ECodeUploadFormItem.PURCHASE_DATE
    );
});
const inputPurchaseTimeTexts = computed(() => {
    return getFormItemText(
        codeUploadComponent,
        ECodeUploadFormItem.PURCHASE_TIME
    );
});
const inputReceiptNumberTexts = computed(() => {
    return getFormItemText(
        codeUploadComponent,
        ECodeUploadFormItem.RECEIPT_NUMBER
    );
});
const inputPlaceOfPurchaseTexts = computed(() => {
    return getFormItemText(
        codeUploadComponent,
        ECodeUploadFormItem.PLACE_OF_PURCHASE
    );
});
const checkboxPrivaciPolicyTexts = computed(() => {
    return getFormItemText(
        codeUploadComponent,
        ECodeUploadFormItem.PRIVACY_POLICY
    );
});

// Computed feedback modals text
const successfulCodeUploadModal = computed(() => {
    return getGroupText(props.component[1], EModal.SUCCESSFULCODEUPLOAD);
});
const apCodeNotWinModal = computed(() => {
    return getGroupText(props.component[1], EModal.APCODENOTWIN);
});
const apCodeConflictModal = computed(() => {
    return getGroupText(props.component[1], EModal.APCODECONFLICT);
});
const apCodeExhoustedModal = computed(() => {
    return getGroupText(props.component[1], EModal.APCODEEXHAUSTED);
});
const apCodeShortTimeGateModal = computed(() => {
    return getGroupText(props.component[1], EModal.APCODESHORTTIMEGATE);
});
const apCodeConflictItselfModal = computed(() => {
    return getGroupText(props.component[1], EModal.APCODECONFLICTITSELF);
});
const couponCodeNotExistModal = computed(() => {
    return getGroupText(props.component[1], EModal.COUPONCODENOTEXIST);
});
const couponCodeConflictModal = computed(() => {
    return getGroupText(props.component[1], EModal.COUPONCODECONFLICT);
});
const couponCodeConflictItselfModal = computed(() => {
    return getGroupText(props.component[1], EModal.COUPONCODECONFLICTITSELF);
});

/**
 * Watch focused input value if change then emit
 */
watch(
    () => focusedInput.value,
    (newVal, oldVal) => {
        if (newVal != oldVal) {
            emit("highlight-change", newVal);
        }
    }
);

watch(
    () => isApCode.value,
    (newVal) => {
        if (newVal) {
            resetFieldsAndClearValidation();
        }
    }
);

watch(
    () => isOnlinePurchase.value,
    (newVal) => {
        if (newVal) {
            resetFieldsAndClearValidation();
        }
    }
);

watch(
    () => isCouponCode.value,
    (newVal) => {
        if (newVal) {
            resetFieldsAndClearValidation();
        }
    }
);

watch(
    () => codeUploadForm.dateOfPurchase,
    () => {
        if (codeUploadForm.timeOfPurchase.length === 5) {
            try {
                const currentTime = moment().format("HHmm");
                const selectedDate = moment(
                    codeUploadForm.dateOfPurchase
                ).format("YYYYMMDD");
                const currentDay = moment().format("YYYYMMDD");

                if (
                    moment(selectedDate).isValid() &&
                    !(selectedDate < currentDay) &&
                    parseInt(codeUploadForm.timeOfPurchase.replace(":", "")) >
                    parseInt(currentTime)
                ) {
                    const timeString =
                        currentTime.toString().slice(0, 2) +
                        ":" +
                        currentTime.toString().slice(2);

                    codeUploadForm.timeOfPurchase = timeString;
                }
            } catch (e) {
                console.error("DateOfPurchase Moment Error", e);
            }
        }
    }
);

watch(
    () => codeUploadForm.timeOfPurchase,
    (newValue) => {
        if (newValue.length === 5) {
            try {
                const currentTime = moment().format("HHmm");
                const selectedDate = moment(
                    codeUploadForm.dateOfPurchase
                ).format("YYYYMMDD");
                const currentDay = moment().format("YYYYMMDD");

                if (
                    moment(selectedDate).isValid() &&
                    !(selectedDate < currentDay) &&
                    parseInt(newValue.replace(":", "")) > parseInt(currentTime)
                ) {
                    const timeString =
                        currentTime.toString().slice(0, 2) +
                        ":" +
                        currentTime.toString().slice(2);

                    codeUploadForm.timeOfPurchase = timeString;
                }
            } catch (e) {
                console.error("TimeOfPurchase Moment Error", e);
            }
        }
    }
);
</script>

<style lang="scss">
.time-mask {
    .el-input__inner {
        text-align: center;
        color: transparent;
        caret-color: black;
    }
}

.el-select-dropdown__wrap {
    max-height: 340px !important;
}

#codeUploadForm {
    .el-form-item__label {
        color: var(--code-upload-form-label-color);
        font-size: var(--code-upload-form-font-size);
    }

    .el-input {
        height: auto;
        border: var(--code-upload-form-input-border-width) solid var(--code-upload-form-input-border-color);
        border-radius: var(--code-upload-form-input-border-radius);
    }

    .el-input__inner {
        color: var(--code-upload-form-input-text-color);
        font-size: var(--code-upload-form-input-font-size);

        &::placeholder {
            color: var(--code-upload-form-input-placeholder-color) !important;
        }
    }

    .el-input__prefix {
        color: var(--code-upload-form-input-text-color);
    }

    .el-input__suffix {
        color: var(--code-upload-form-input-text-color);
    }

    .el-select .el-input .el-select__caret {
        color: var(--code-upload-form-input-text-color);
    }

    .time-mask {
        .el-input__inner {
            text-align: center;
            color: transparent;
            caret-color: black;
        }
    }

    .el-input__wrapper {
        height: var(--code-upload-form-input-height);
        padding: var(--code-upload-form-input-padding);
        border-radius: var(--code-upload-form-input-border-radius);
        background-color: var(--code-upload-form-input-background-color) !important;
        box-shadow: none !important;
    }

    .el-input.is-active,
    .el-input__inner:focus {
        border-color: var(--code-upload-form-input-focus-border-color);
    }

    .el-form-item.is-error .el-input__inner,
    .el-form-item.is-error .el-input__inner:focus,
    .el-form-item.is-error .el-textarea__inner,
    .el-form-item.is-error .el-textarea__inner:focus,
    .el-message-box__input input.invalid,
    .el-message-box__input input.invalid:focus {
        border-color: #e44f60;
    }

    .el-form-item__label::before {
        content: "" !important;
    }

    .el-form-item__error {
        color: white;
        background-color: #e44f60;
        border-radius: 4px;
        padding: 2px 4px;
        margin-top: 5px;
        right: 0;
        left: initial;
        z-index: 1;

        &::before {
            position: absolute;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid #e44f60;
            width: 0;
            height: 0;
            display: block;
            content: "";
            top: -5px;
            right: 12px;
        }
    }

    .el-input__validateIcon {
        font-size: 18px;

        &.el-icon-circle-check {
            color: #67c23a;
        }

        &.el-icon-circle-close {
            color: #e44f60;
        }
    }

    .el-checkbox {
        display: flex;
        align-items: center;
        height: auto !important;
    }

    .el-checkbox__label {
        white-space: normal;
        word-wrap: break-word;
        line-height: 1.25;
        color: var(--code-upload-checkbox-label-color);

        p {
            margin-bottom: 0;
        }

        a {
            color: var(--code-upload-checkbox-label-color);
        }
    }

    .el-checkbox__inner {
        width: 24px;
        height: 24px;
        border-radius: 0.35rem;

        &::after {
            border-width: 3px;
            width: 5px;
            height: 12px;
            left: 7px;
            top: 2px;
            transition: initial;
            border-color: var(--code-upload-checkbox-active-color);
        }
    }

    .el-checkbox__input.is-checked+.el-checkbox__label {
        color: var(--code-upload-checkbox-label-color);
    }

    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: var(--code-upload-checkbox-label-bg-color);
        border-color: var(--code-upload-checkbox-label-bg-color);
    }

    .submit-btn {
        border: 0;
        font-size: var(--code-upload-form-submit-font-size);
        color: var(--code-upload-form-submit-color);
        border: var(--code-upload-form-submit-border-width) solid var(--code-upload-form-submit-border-color);
        background-color: var(--code-upload-form-submit-background-color);
        border-radius: var(--code-upload-form-submit-border-radius);
        transition: all 0.25 ease-in-out;
        padding: var(--code-upload-form-submit-padding);

        &:hover {
            color: var(--code-upload-form-submit-color-hover);
            background-color: var(--code-upload-form-submit-background-color-hover);
            border: var(--code-upload-form-submit-border-width-hover) solid var(--code-upload-form-submit-border-color-hover);
            transition: all 0.25 ease-in-out;
        }

        &.is-disabled {
            background: grey !important;
            border-color: grey !important;
            color: white !important;
        }
    }

    .receipt-image {
        .el-form-item__content {
            display: block;
        }
    }

    .prizific-file-text {
        color: var(--recipe-upload-form-label-color);
        font-size: var(--recipe-upload-form-label-font-size);
    }

    .prizific-file-input {
        position: relative;
        display: flex;
        align-items: center;

        .el-upload-list__item-thumbnail {
            position: relative;
            display: flex;
            width: 100%;
            min-width: 100px;
            min-height: 100px;
            overflow: hidden;
            z-index: 1;
            color: var(--code-upload-form-input-text-color);
            font-size: var(--recipe-upload-form-input-font-size);
            pointer-events: none;

            &.no-thumbnail {
                border: 1px solid;
            }

            &.disabled {
                filter: grayscale(100%);
            }

            .no-thumbnail-icon {
                font-size: 24px;
            }

            .prizific-file-input-clear {
                opacity: 0;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(0, 0, 0, 0.25);
                transition: opacity 0.25s ease-in-out;
            }
        }

        .prizific-file-input-label {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            margin-left: 0;
            display: inline-block;
            z-index: 0;
            background-color: var(--code-upload-form-input-background-color);
            border-radius: var(--code-upload-form-input-border-radius);
            border-style: solid;
            border-color: var(--code-upload-form-input-border-color);
            border-width: var(--code-upload-form-input-border-width);
            font-size: var(--code-upload-form-input-font-size);

            .prizific-file-input-label-title {
                font-weight: bold;
            }

            .prizific-file-input-label-title,
            .prizific-file-input-label-desc {
                line-height: 24px;
            }

            input[type="file"] {
                display: none;
            }

            &.disabled {
                cursor: no-drop;
            }
        }
    }

    .error-message {
        margin-top: 10px;
    }

    .prizific-file {
        position: relative;

        img,
        embed {
            max-width: 100%;
            height: 200px;
            object-fit: contain;
        }

        embed {
            top: 8px;
        }

        img {
            margin: 0 auto;
            display: inherit;
        }

        .prizific-file-input-clear-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            font-size: 24px;
            line-height: 1;
            cursor: pointer;
            top: -10px;
            right: 0;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            border: 2px solid;
            color: var(--code-upload-form-input-receipt-image-close-icon-color);
            border-color: var(--code-upload-form-input-receipt-image-close-icon-color);
        }
    }
}
</style>
