<template>
    <div class="multiple-tag-input" :style="selectedPromoProductMergedStyle">
        <div v-if="!hasTag" class="multiple-tag-input-placeholder">
            {{ placeholder }}
        </div>
        <el-tag
            v-for="promoProduct in hasTag
                ? PurchasedPromotionProductStore.selectedPromoProducts
                : []"
            :key="promoProduct.id"
            size="small"
            closable
            @close="handleTagClose(promoProduct.id)"
        >
            <span class="badge rounded-pill">
                {{ promoProduct.quantity }}
            </span>
            <span>{{ promoProduct.name }}</span>
        </el-tag>
        <div class="multiple-tag-input-suffix">
            <i class="el-icon-arrow-down"></i>
        </div>

        <!-- Helper multiple select for validation -->

        <el-select
            :modelValue="PurchasedPromotionProductStore.selectedPromoProductsId"
            multiple
            collapse-tags
            class="d-none"
        >
            <el-option
                v-for="item in PurchasedPromotionProductStore.selectedPromoProductsId"
                :key="item"
                :label="item"
                :value="item"
            >
            </el-option>
        </el-select>

        <!-- Helper multiple select for validation end -->
    </div>
</template>

<script setup lang="ts">
import _ from "lodash";
import { useCampaignDataStore } from "@/store/campaign/campaignData";
import { usePurchasedPromotionProductStore } from "@/store/components/purchasedPromotionProduct/purchasedPromotionProduct";
import { IComponent } from "~~/interfaces/PrizificPageBuilder/IComponent";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { EProperty } from "~~/enums/PrizificPageBuilder/EProperty";

const campaignDataStore = useCampaignDataStore();
const PurchasedPromotionProductStore = usePurchasedPromotionProductStore();

interface Props {
    placeholder: string;
    modelValue: Array<number>;
    component: IComponent<EComponents>;
}

/**
 * Define props
 */
const props = defineProps<Props>();

/**
 * Has tag
 * @return {boolean}
 */
const hasTag = computed((): boolean =>
    Boolean(PurchasedPromotionProductStore.selectedPromoProductsId?.length)
);

const placeholder = computed((): string => props.placeholder);

/**
 * Define emits
 */
const emit = defineEmits(["update:modelValue"]);

const selectedPromoProductStyleObject = computed(() => {
    return getStyle(props.component.style, "modalPurchasePromotionProducts");
});

const selectedPromoProductMergedStyle = computed(() => {
    return {
        "--selected-promo-product-background-color":
            selectedPromoProductStyleObject.value[EProperty.BACKGROUND_COLOR],
        "--selected-promo-product-text-color":
            selectedPromoProductStyleObject.value[EProperty.COLOR],
        "--selected-promo-product-border-color":
            selectedPromoProductStyleObject.value[EProperty.BORDER_COLOR],
    };
});

/**
 * Handle tag on close
 */
function handleTagClose(id: number | string) {
    PurchasedPromotionProductStore.selectedPromoProductsId = [];

    PurchasedPromotionProductStore.selectedPromoProducts =
        PurchasedPromotionProductStore.selectedPromoProducts.filter(
            (element: any) => element.id !== id
        );

    PurchasedPromotionProductStore.selectedPromoProducts.forEach((item: any) =>
        PurchasedPromotionProductStore.selectedPromoProductsId.push(item.id)
    );

    emit(
        "update:modelValue",
        PurchasedPromotionProductStore.selectedPromoProductsId
    );
}

watch(
    () => _.cloneDeep(PurchasedPromotionProductStore.selectedPromoProducts),
    () => {
        emit(
            "update:modelValue",
            PurchasedPromotionProductStore.selectedPromoProductsId
        );
    }
);
</script>

<style lang="scss">
.multiple-tag-input {
    position: relative;
    display: flex;
    width: 100%;
    color: #e44f60;
    padding: 0.5rem 1.75rem 0.5rem 0.5rem;
    border-radius: var(--code-upload-form-input-border-radius);
    border: var(--code-upload-form-input-border-width) solid
        var(--code-upload-form-input-border-color);
    background-color: white;
    flex-wrap: wrap;
    gap: 0.5rem;
    align-items: center;
    cursor: pointer;
    min-height: 44px;
    background-color: var(--code-upload-form-input-background-color);

    .multiple-tag-input-placeholder {
        color: var(--code-upload-form-input-placeholder-color);
        padding-left: 0.4rem;
        line-height: 1;
    }

    .badge {
        color: var(--selected-promo-product-background-color);
        background-color: white;
        margin-right: 0.25rem;
        display: flex;
        align-items: center;
    }

    .el-tag {
        user-select: none;
        position: relative;
        height: 32px !important;
        padding: 4px 8px !important;
        font-weight: bold;
        color: var(--selected-promo-product-text-color) !important;
        border-color: var(--selected-promo-product-background-color) !important;
        background: var(--selected-promo-product-background-color) !important;

        .el-tag__content {
            display: flex;
            align-items: center;
        }

        .el-tag__close {
            font-weight: bold;
            height: 17px !important;
            width: 17px !important;
            font-size: 16px !important;
            padding: 2px;
            line-height: 13px;
            color: var(--selected-promo-product-text-color) !important;
            transition: background 0.15s ease-in-out;

            &:hover {
                color: var(--selected-promo-product-text-color) !important;
                background: var(--selected-promo-product-background-color);
                transition: background 0.15s ease-in-out;
            }
        }
    }

    .multiple-tag-input-suffix {
        position: absolute;
        right: 0.5rem;
        top: 50%;
        width: 14px;
        height: 14px;
        margin-left: 0;
        margin-right: 0;
        transform: translateY(-50%);
        color: #c0c4cc;

        i {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}
</style>
