<template>
    <section id="resztvevo-termekek">
        <div
            class="promo-products-swiper py-5 overflow-hidden"
            :style="[promoProductSwiperStyleObject]"
        >
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-10 offset-md-1">
                        <h2
                            class="fw-900 font-italic mt-2 mb-6 text-center"
                            :style="titleStyleObject"
                            v-if="section.sectionTitleRaw"
                            v-html="section.sectionTitleRaw"
                        ></h2>

                        <div
                            class="fw-900 font-italic mt-2 mb-6 text-center"
                            :style="sectionParagraphStyle"
                            v-if="
                                section.sectionParagraphRaw &&
                                section.sectionParagraphRaw !== '<p><br></p>'
                            "
                            v-html="section.sectionParagraphRaw"
                        ></div>
                        <client-only>
                            <!-- Swiper -->
                            <swiper
                                :modules="modules"
                                :breakpoints="data.breakpoints"
                                :pagination="data.pagination"
                                :navigation="data.navigation"
                                @swiper="onSwiper"
                                @slideChange="onSlideChange"
                            >
                                <swiper-slide
                                    v-for="promotion in campaignDataStore.campaignPromotions"
                                    :key="promotion.id"
                                >
                                    <div
                                        class="d-flex flex-column justify-content-center align-items-center"
                                    >
                                        <picture>
                                            <source
                                                :srcset="
                                                    useImageMediaUrl(
                                                        promotion.imageUrl,
                                                        ImageFormat.WEBP,
                                                        promoProductImageSize
                                                        
                                                    )
                                                "
                                                type="image/webp"
                                            />

                                            <img
                                                :style="
                                                    promoProductImageStyleObject
                                                "
                                                :src="
                                                    promotion.imageUrl
                                                        ? useImageMediaUrl(
                                                              promotion.imageUrl,
                                                              ImageFormat.PNG,
                                                              promoProductImageSize
                                                              
                                                          )
                                                        : useCdnUrl(
                                                              '/images/150x150-image-placeholder.png'
                                                          )
                                                "
                                                alt=""
                                            />
                                        </picture>
                                        <div
                                            class="text-center mt-2 fw-bold"
                                            :style="promoProductNameStyleObject"
                                        >
                                            {{ promotion.name }}
                                        </div>
                                    </div>
                                </swiper-slide>

                                <!-- Swiper prev/next buttons -->
                                <div
                                    class="swiper-button-prev d-none d-lg-block"
                                    :style="swiperArrowColorStyleObject"
                                ></div>
                                <div
                                    class="swiper-button-next d-none d-lg-block"
                                    :style="swiperArrowColorStyleObject"
                                ></div>
                                <!-- Swiper prev/next buttons end -->

                                <!-- Swiper pagination -->
                                <div
                                    :style="swiperPaginationStyleObject"
                                    class="swiper-pagination d-block d-lg-none"
                                ></div>
                                <!-- Swiper pagination end -->
                            </swiper>
                            <!-- Swiper end -->
                        </client-only>

                        <div
                            :class="{
                               'staro-color': campaignDataStore.campaignUUId == 'a46bdb28-0c9b-463b-a48e-b2e78acd71b8' || campaignDataStore.campaignUUId == 'cc601dc3-716a-4cb3-a994-14d3ec111761'
                            }"


                            class="has-link fw-900 font-italic mt-2 mb-6 text-center"
                            v-if="
                                afterPromoProductText &&
                                afterPromoProductText.textRaw &&
                                afterPromoProductText.textRaw !== '<p><br></p>'
                            "
                            :style="sectionAfterPromoProductsStyle"
                            v-html="afterPromoProductText.textRaw"
                        ></div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Prizific page builder tool -->
        <client-only>
            <PrizificPageBuilderTool v-if="editable" :section="section" />
        </client-only>
        <!-- Prizific page builder tool end -->
    </section>
</template>

<script setup lang="ts">
import { useCampaignDataStore } from "@/store/campaign/campaignData";
import { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { IComponent } from "~~/interfaces/PrizificPageBuilder/IComponent";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { useVibrate } from "@vueuse/core";

const runtime = useRuntimeConfig();
const campaignDataStore = useCampaignDataStore();

interface Props {
    section: IComponent<EComponents> & any;
    editable: boolean;
}

/**
 * Define props
 */
const props = defineProps<Props>();

const onSwiper = () => {};
const onSlideChange = () => {
    // This vibrates the device for 300 ms
    const { vibrate, stop, isSupported } = useVibrate({ pattern: [300] });

    // Start the vibration, it will automatically stop when the pattern is complete:
    if (isSupported) {
        vibrate();
    }
};
const modules = [Navigation, Pagination, A11y];

const data = reactive({
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
        dynamicBullets: true,
    },
    breakpoints: {
        1024: {
            slidesPerView: 4,
            spaceBetween: 10,
            centeredSlides: false,
        },
        768: {
            slidesPerView: 3,
            spaceBetween: 10,
            centeredSlides: false,
        },
        640: {
            slidesPerView: 2,
            spaceBetween: 10,
            centeredSlides: true,
        },
        320: {
            slidesPerView: 2,
            spaceBetween: 10,
            centeredSlides: true,
        },
    },
});

/**
 * Get title style
 * @returns {Array<any>} styleObject
 */
const titleStyleObject = computed(() => {
    return getStyle(props.section.style, "promoProductsTitle");
});

/**
 * Get paragraph style
 * @returns {Array<any>} styleObject
 */
const sectionParagraphStyle = computed(() => {
    return getStyle(props.section.style, "promoProductsParagraph");
});

/**
 * Create promo products background-image inline style object
 */
const sectionBackgroundImage = computed(() => {
    return props.section?.src
        ? {
              backgroundImage: `url(${useMediaUrl(props.section.src)})`,
          }
        : "";
});

const imageXs = `url('${useMediaUrl(props.section.images[0].src)}')`;
const imageSm = `url('${useMediaUrl(props.section.images[1].src)}')`;
const imageMd = `url('${useMediaUrl(props.section.images[2].src)}')`;
const imageLg = `url('${useMediaUrl(props.section.images[3].src)}')`;
const imageXl = `url('${useMediaUrl(props.section.images[4].src)}')`;
const imageXxl = `url('${useMediaUrl(props.section.images[5].src)}')`;

/**
 * Get paragraph style
 * @returns {Array<any>} styleObject
 */
const sectionAfterPromoProductsStyle = computed(() => {
    return getStyle(props.section.style, "afterPromoProductText");
});

const afterPromoProductText = computed(() => {
    const component = props.section.getComponents().filter((component) => {
        return component.belongsTo == "afterPromoProductText";
    });

    if (component && component[0]) {
        return component[0];
    }
    return null;
});

/**
 * Get promo product image style
 * @returns {Object} styleObject
 */
const promoProductImageStyleObject = computed(() => {
    return getStyle(props.section.style, "promoProductsImage");
});

const promoProductImageSize = computed(() => {
    return props.section.style.filter(
        (item: any) => item.belongsTo == "promoProductsImage"
    )[0]?.value;
});

/**
 * Get promo products background style
 */
const promoProductSwiperStyleObject = computed(() => {
    return getStyle(props.section.style, "promoProductsBackground");
});

/**
 * Get promo products pagination style
 * @returns {Object} styleObject
 */
const swiperPaginationStyleObject = computed(() => {
    const promoProductsSwiperPagination = getStyle(
        props.section.style,
        "promoProductsSwiperPagination"
    );

    const promoProductsSwiperPaginationActive = getStyle(
        props.section.style,
        "promoProductsSwiperPaginationActive"
    );

    const style = {
        "--swiper-pagination-bullet": promoProductsSwiperPagination?.color,
        "--swiper-pagination-color-active":
            promoProductsSwiperPaginationActive.color,
    };

    return style;
});

/**
 * Get promo products name style
 */
const promoProductNameStyleObject = computed(() => {
    return getStyle(props.section.style, "promoProductsName");
});

/**
 * Get swiper arrow style
 * @returns {Array<any>} styleObject
 */
const swiperArrowColorStyleObject = computed(() => {
    const promoProductsSwiperArrow = getStyle(
        props.section.style,
        "promoProductsSwiperArrow"
    );

    const style = {
        color: promoProductsSwiperArrow.color,
        "--swiper-navigation-size": promoProductsSwiperArrow.fontSize,
    };

    return style;
});
</script>

<style lang="scss">
.promo-products-swiper {
    min-height: 56px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    background-image: v-bind("imageXs");

    .swiper-wrapper {
        padding-bottom: 24px;
    }

    .swiper-button-disabled {
        display: none !important;
    }

    .swiper-button-prev,
    .swiper-button-next {
        &::after {
            font-weight: bold;
        }
    }

    .swiper-pagination {
        .swiper-pagination-bullet {
            background-color: var(--swiper-pagination-bullet);

            &.swiper-pagination-bullet-active {
                background-color: var(--swiper-pagination-color-active);
            }
        }
    }

    @media (min-width: 576px) {
        background-image: v-bind("imageSm");
    }

    @media (min-width: 768px) {
        background-image: v-bind("imageMd");
    }

    @media (min-width: 992px) {
        background-image: v-bind("imageLg");
    }

    @media (min-width: 1200px) {
        background-image: v-bind("imageXl");
    }

    @media (min-width: 1400px) {
        background-image: v-bind("imageXxl");
    }
}

.has-link {
    a {
        font-weight: normal;
        
        text-decoration: none;
        border-radius: 24px;
        font-size: 18px;
        padding: 4px 24px;

        &.staro-color{
            color: #faf1d1;
            background-color: #114634;
            border-color: #114634;

            &:hover {
                color: #faf1d1;
                background-color: #114634;
                border-color: #114634;
            }
        }
    }

}
</style>
