<template>
    <footer>
        <div
            class="overflow-hidden footer-wrapper pb-6 pb-sm-0"
            :style="[backgroundStyleObject]"
        >
            <div class="container" :style="{ minHeight: '256px' }">
                <div
                    :style="{ minHeight: '256px' }"
                    class="d-flex flex-column flex-lg-row justify-content-center align-items-center text-center text-lg-start align-items-stretch pb-6"
                >
                    <!-- <el-image
                        class="ms-auto me-auto ms-lg-0 me-lg-12 mt-6"
                        src="~/assets/images/prizific-test-page-logo.png"
                        :style="brandLogoStyleObject"
                    >
                        <template #placeholder>
                            <div class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </template>
                    </el-image> -->
                    <div>
                        <img
                            v-if="brandLogoSrc"
                            :style="brandLogoStyleObject"
                            class="ms-auto me-auto ms-lg-0 me-lg-12 mt-6"
                            :src="useMediaUrl(brandLogoSrc)"
                            alt=""
                        />
                    </div>

                    <div
                        class="d-flex flex-column ms-auto me-auto me-lg-0 ms-lg-0 mt-6"
                    >
                        <h4
                            class="text-uppercase fw-bold px-2"
                            :style="firstGroupTitleStyleObject"
                        >
                            Dokumentumok
                        </h4>

                        <ul class="nav flex-column mt-2 gap-3 px-2">
                            <template
                                v-for="(component, index) in documentComponents"
                                :key="index"
                            >
                                <NavItem :component="component" />
                            </template>

                            <template
                                v-if="
                                    campaignData.uuid ==
                                        'a46bdb28-0c9b-463b-a48e-b2e78acd71b8' ||
                                    campaignData.uuid ==
                                        'cc601dc3-716a-4cb3-a994-14d3ec111761'
                                "
                            >
                                <div id="consent_blackbar"></div>
                                <div
                                    id="teconsent"
                                    :style="cookieSettingsStyleObject"
                                ></div>
                            </template>

                            <a
                                v-else
                                :style="cookieSettingsStyleObject"
                                class="cookie-consent-dialog-open-button"
                                @click="
                                    cookieSettings.setActivePage('settings');
                                    cookieSettings.showCookieDialog();
                                "
                                type="button"
                            >
                                Cookie beállítások
                            </a>
                        </ul>
                    </div>

                    <div
                        class="d-flex flex-column ms-auto me-auto me-lg-0 mt-6"
                    >
                        <h4
                            v-if="socialComponents.length"
                            class="text-uppercase fw-bold px-2 text-center text-lg-end"
                            :style="thirdGroupTitleStyleObject"
                        >
                            Kövess minket
                        </h4>
                        <ul
                            class="nav justify-content-center justify-content-lg-end gap-3 px-2"
                        >
                            <template
                                v-for="(
                                    socialComponent, index
                                ) in socialComponents"
                                :key="index"
                            >
                                <NavItem :component="socialComponent" />
                            </template>
                        </ul>
                        <div class="mt-auto px-2">
                            <a
                                v-if="
                                    campaignData.uuid ==
                                        'cc601dc3-716a-4cb3-a994-14d3ec111761' ||
                                    campaignData.uuid ==
                                        'a46bdb28-0c9b-463b-a48e-b2e78acd71b8'
                                "
                                href="https://borsodi.hu/drink-responsibility"
                                target="_blank"
                            >
                                <img
                                    class="mt-6"
                                    width="224"
                                    height="118"
                                    :src="
                                        useCdnUrl(
                                            '/images/consume_responsibly.webp'
                                        )
                                    "
                                    alt=""
                                />
                            </a>
                            <a
                                v-if="
                                    campaignData.uuid !==
                                        'cc601dc3-716a-4cb3-a994-14d3ec111761' &&
                                    campaignData.uuid !==
                                        'a46bdb28-0c9b-463b-a48e-b2e78acd71b8' &&
                                    campaignData.uuid !==
                                        '22e3a019-99dc-4c20-9cf0-ade544aa7ee4' &&
                                    campaignData.uuid !==
                                        'cca63d5c-c47c-45be-8a3f-3c98e0037b7f'
                                "
                                class="d-flex flex-row fw-bold px-0 ms-auto text-decoration-none"
                                href="https://prizific.com"
                                target="_blank"
                                :style="{
                                    color: getContrast(
                                        backgroundStyleObject.backgroundColor
                                    ),
                                }"
                            >
                                <span
                                    class="d-flex align-items-center me-auto ms-lg-auto me-lg-0 powered-by"
                                >
                                    Powered by
                                    <img
                                        class="ms-1"
                                        :src="prizificLogo"
                                        alt="prizific logo"
                                    />
                                </span>
                            </a>
                            <span
                                class="d-flex flex-row px-0 ms-auto text-decoration-none text-white"
                                v-if="
                                    campaignData.uuid ==
                                    'cca63d5c-c47c-45be-8a3f-3c98e0037b7f'
                                "
                                >© 2024 Kékkúti Ásványvíz Zrt.</span
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Prizific page builder tool -->
        <client-only>
            <PrizificPageBuilderTool v-if="editable" :section="section" />
        </client-only>
        <!-- Prizific page builder tool end -->
    </footer>
</template>

<script lang="ts">
import { useCampaignDataStore } from "@/store/campaign/campaignData";
import NavItem from "../../entities/PrizificPageBuilder/components/NavItem";
import { IComponent } from "../../interfaces/PrizificPageBuilder/IComponent";
import { getStyle } from "../../composables/styleHelper";
import { useCookieSettingsDialogStore } from "@/store/components/cookieSettingsDialog/cookieSettingsDialog";

export default {
    props: {
        section: {
            required: true,
            type: Object,
        },
        editable: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        const cookieSettingsDialogStore = useCookieSettingsDialogStore();
        const campaignDataStore: any = useCampaignDataStore();
        const runtime = useRuntimeConfig();

        return {
            campaignDataStore: campaignDataStore,
            campaignData: campaignDataStore.campaignData,
            cookieSettings: cookieSettingsDialogStore,
            runtime: runtime,
            socialLinks: [{}],
            imageXs: `url('${useMediaUrl(this.section.images[0]?.src) ?? ""}')`,
            imageSm: `url('${useMediaUrl(this.section.images[1]?.src) ?? ""}')`,
            imageMd: `url('${useMediaUrl(this.section.images[2]?.src) ?? ""}')`,
            imageLg: `url('${useMediaUrl(this.section.images[3]?.src) ?? ""}')`,
            imageXl: `url('${useMediaUrl(this.section.images[4]?.src) ?? ""}')`,
            imageXxl: `url('${
                useMediaUrl(this.section.images[5]?.src) ?? ""
            }')`,
        };
    },
    computed: {
        /**
         * Get background style
         * @returns {any} object
         */
        backgroundStyleObject(): any {
            return getStyle((this.section as any)?.style, "footerBackground");
        },

        /**
         * Get background-image style
         * @returns {any} object
         */
        sectionBackgroundImage() {
            return this.section.src
                ? {
                      backgroundImage: `url(${useMediaUrl(this.section.src)})`,
                  }
                : "";
        },

        /**
         * Get first group title style
         * @returns {any} object
         */
        firstGroupTitleStyleObject(): any {
            return getStyle((this.section as any)?.style, "footerFirstGroup");
        },

        /**
         * Get first group title style
         * @returns {any} object
         */
        secondGroupTitleStyleObject(): any {
            return getStyle((this.section as any)?.style, "footerSecondGroup");
        },

        /**
         * Get first group title style
         * @returns {any} object
         */
        thirdGroupTitleStyleObject(): any {
            return getStyle((this.section as any)?.style, "footerThirdGroup");
        },

        /**
         * Get cookie settings button style
         * @returns {any} object
         */
        cookieSettingsStyleObject(): any {
            return getStyle((this.section as any)?.components[3]?.style);
        },

        /**
         * Get brand logo style
         * @returns {any} object
         */
        brandLogoStyleObject(): any {
            return getStyle((this.section as any)?.components[0]?.style);
        },

        brandLogoSrc(): any {
            return this.section.components[0]?.src;
        },

        prizificLogo(): any {
            return getContrast(this.backgroundStyleObject.backgroundColor) ==
                "black"
                ? useCdnUrl("/images/prizific-footer-logo-red.png")
                : useCdnUrl("/images/prizific-footer-logo-white.png");
        },

        /**
         * Get footer documents components
         * @returns {Array<IComponent<NavItem>}
         */
        documentComponents(): Array<IComponent<NavItem>> {
            let components: any[] = [];

            (this.section as any).components.forEach(
                (item: any, index: any) => {
                    if (index > 0 && index < 6) {
                        if (
                            item.title == "Játékszabályzat" &&
                            this.campaignData?.rulesPdfFile &&
                            this.campaignData?.rulesPdfFile != "" &&
                            this.campaignData?.rulesPdfFile != null
                        ) {
                            item.to = useMediaUrl(
                                this.campaignData.rulesPdfFile
                            );
                            components.push(item);
                        } else if (
                            item.title == "Adatvédelmi nyilatkozat" &&
                            this.campaignData?.privacyPolicyPdfFile &&
                            this.campaignData?.privacyPolicyPdfFile != "" &&
                            this.campaignData?.privacyPolicyPdfFile != null
                        ) {
                            //INFO: temporary solution
                            //TODO: replace this in next campaigns!
                            // item.to =
                            //     "https://eu.mondelezinternational.com/privacy-notice?sc_lang=hu-HU";
                            item.to = useMediaUrl(
                                this.campaignData.privacyPolicyPdfFile
                            );
                            components.push(item);
                        } else if (
                            item.title == "Cookie tájékoztató" &&
                            this.campaignData?.cookiePolicyPdfFile &&
                            this.campaignData?.cookiePolicyPdfFile != "" &&
                            this.campaignData?.cookiePolicyPdfFile != null
                        ) {
                            item.to = useMediaUrl(
                                this.campaignData.cookiePolicyPdfFile
                            );
                            components.push(item);
                        } else if (
                            item.title == "Felhasználási feltételek" &&
                            this.campaignData?.termsOfUsePdfFile &&
                            this.campaignData?.termsOfUsePdfFile != "" &&
                            this.campaignData?.termsOfUsePdfFile != null
                        ) {
                            item.to = useMediaUrl(
                                this.campaignData.termsOfUsePdfFile
                            );
                            components.push(item);
                        } else if (
                            item.title == "Jogi nyilatkozat" &&
                            this.campaignData?.legalNoticePdfFile &&
                            this.campaignData?.legalNoticePdfFile != "" &&
                            this.campaignData?.legalNoticePdfFile != null
                        ) {
                            item.to = useMediaUrl(
                                this.campaignData.legalNoticePdfFile
                            );
                            components.push(item);
                        }
                    }
                }
            );

            return components;
        },

        /**
         * Get footer menu components
         * @returns {Array<IComponent<NavItem>}
         */
        menuComponents(): Array<IComponent<NavItem>> {
            let components: any[] = [];

            (this.section as any).components.forEach(
                (item: any, index: any) => {
                    if (index > 5 && index <= 10) {
                        if (
                            item.title == "Résztvevő termékek" &&
                            this.campaignDataStore?.campaignPromotions?.length
                        ) {
                            components.push(item);
                        } else if (item.title !== "Résztvevő termékek") {
                            components.push(item);
                        }
                    }
                }
            );

            return components;
        },

        /**
         * Get footer social components
         * @returns {Array<IComponent<NavItem>}
         */
        socialComponents() {
            let components: any[] = [];

            (this.section as any).components.forEach(
                (item: any, index: any) => {
                    if (index > 9 && index < 14) {
                        if (
                            item.icon == "facebook" &&
                            this.campaignData?.socialFacebook
                        ) {
                            item.to = this.campaignData.socialFacebook;
                            components.push(item);
                        } else if (
                            item.icon == "instagram" &&
                            this.campaignData?.socialInstagram
                        ) {
                            item.to = this.campaignData.socialInstagram;
                            components.push(item);
                        } else if (
                            item.icon == "youtube" &&
                            this.campaignData?.socialYoutube
                        ) {
                            item.to = this.campaignData.socialYoutube;
                            components.push(item);
                        } else if (
                            item.icon == "tiktok" &&
                            this.campaignData?.socialTiktok
                        ) {
                            item.to = this.campaignData.socialTiktok;
                            components.push(item);
                        }
                    }
                }
            );

            return components;
        },
    },
};
</script>

<style lang="scss" scoped>
footer {
    .nav-item {
        justify-content: center;

        @media (min-width: 768px) {
            justify-content: flex-start;
        }
    }
}

.cookie-consent-dialog-open-button {
    font-size: 13px;
    color: white;
    text-decoration: none;
}

.powered-by {
    font-family: "Poppins";
    font-size: 13px;
}

.footer-wrapper {
    background-image: v-bind("imageXs");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: 576px) {
        background-image: v-bind("imageSm");
    }

    @media (min-width: 768px) {
        background-image: v-bind("imageMd");
    }

    @media (min-width: 992px) {
        background-image: v-bind("imageLg");
    }

    @media (min-width: 1200px) {
        background-image: v-bind("imageXl");
    }

    @media (min-width: 1400px) {
        background-image: v-bind("imageXxl");
    }
}
</style>
