<script setup lang="ts">
import { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { EProperty } from "~~/enums/PrizificPageBuilder/EProperty";
import { useUploadedCodesStore } from "~~/store/components/uploadedCodes/uploadedCodes";
import { storeToRefs } from "pinia";

const uploadedCodesStore = useUploadedCodesStore();
//This will wait until all code not loaded
await uploadedCodesStore.getUploadedCodes(true);
const { uploadedCodes } = storeToRefs(uploadedCodesStore);



interface Props {
    component: any;
}

/**
 * Define props
 */
const props = defineProps<Props>();

const onSwiper = () => { };
const onSlideChange = () => { };
const modules = [Navigation, Pagination, A11y];

const data = reactive({
    loading: false,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
        dynamicBullets: true,
    },
    breakpoints: {
        1024: {
            slidesPerView: 1,
            spaceBetween: 10,
            centeredSlides: false,
        },
        768: {
            slidesPerView: 1,
            spaceBetween: 10,
            centeredSlides: false,
        },
        640: {
            slidesPerView: 1,
            spaceBetween: 10,
            centeredSlides: false,
        },
        320: {
            slidesPerView: 1,
            spaceBetween: 10,
            centeredSlides: false,
        },
    },
});

const cardHeadMergedStyle = computed(() => {
    const cardComponent = props.component[0];
    const cardHeadStyle = getStyle(
        cardComponent.style,
        "uploadedCodesCardHead"
    );

    return {
        "--card-head-background-color":
            cardHeadStyle[EProperty.BACKGROUND_COLOR],
        "--card-head-font-size": cardHeadStyle[EProperty.FONT_SIZE],
        "--card-head-text-color": cardHeadStyle[EProperty.COLOR],
    };
});
const cardBodyMergedStyle = computed(() => {
    const cardComponent = props.component[0];
    const cardBodyStyle = getStyle(
        cardComponent.style,
        "uploadedCodesCardBody"
    );
    const cardBodyWinnerStyle = getStyle(
        cardComponent.style,
        "uploadedCodesCardBodyWinnerCodeUpload"
    );

    return {
        "--card-body-background-color":
            cardBodyStyle[EProperty.BACKGROUND_COLOR],
        "--card-body-font-size": cardBodyStyle[EProperty.FONT_SIZE],
        "--card-body-text-color": cardBodyStyle[EProperty.COLOR],
        "--card-body-winner-background-color":
            cardBodyWinnerStyle[EProperty.BACKGROUND_COLOR],
        "--card-body-winner-font-size":
            cardBodyWinnerStyle[EProperty.FONT_SIZE],
        "--card-body-winner-text-color": cardBodyWinnerStyle[EProperty.COLOR],
    };
});
const cardSwiperMergedStyle = computed(() => {
    const cardComponent = props.component[0];
    const cardSwiperPaginationStyle = getStyle(
        cardComponent.style,
        "uploadedCodesCardSwiperPagination"
    );
    const cardSwiperNavigationStyle = getStyle(
        cardComponent.style,
        "uploadedCodesCardSwiperNavigation"
    );

    return {
        "--card-swiper-pagination-background-color":
            cardSwiperPaginationStyle[EProperty.BACKGROUND_COLOR],
        "--card-swiper-pagination-border-color":
            cardSwiperPaginationStyle[EProperty.BORDER_COLOR],
        "--card-swiper-navigation-color":
            cardSwiperNavigationStyle[EProperty.COLOR],
    };
});
</script>

<template>
    <div class="row my-uploaded-codes-list">
        <div v-if="uploadedCodes.length==0" class="text-center">
            Még nincs feltöltött kódod.
        </div>

        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4" v-for="myUploadedCode in uploadedCodes"
            :key="myUploadedCode.id">
            <div class="muc-list-item d-flex flex-column" :style="[
                cardHeadMergedStyle,
                cardBodyMergedStyle,
                cardSwiperMergedStyle,
            ]">
                <div class="muc-list-item-uploaded-date">
                    Feltöltés ideje:
                    <b>{{ myUploadedCode.uploadedAt }}</b>
                </div>
                <div v-if="myUploadedCode.promotions" class="muc-list-item-promo-product-image">
                    <!-- Swiper -->
                    <swiper ref="carousel" class="swiper" :modules="modules" :breakpoints="breakpoints"
                        :pagination="pagination" :navigation="navigation" @swiper="onSwiper"
                        @slideChange="onSlideChange">
                        <swiper-slide class="pb-4" v-for="(
                                promoProduct, index
                            ) in myUploadedCode.promotions" :key="index">
                            <img class="mt-auto mb-auto" :src="useMediaUrl(promoProduct.imageUrl)"
                                :alt="promoProduct.name" />
                            <!-- <img
                                class="mt-auto mb-auto"
                                :src="`${promoProduct.imageUrl}`"
                                :alt="promoProduct.name"
                            /> -->
                            <div class="text-center mt-2">
                                {{ promoProduct.name }}
                            </div>
                        </swiper-slide>

                        <!-- Swiper prev/next buttons -->
                        <div class="swiper-button-prev" slot="button-prev"></div>
                        <div class="swiper-button-next" slot="button-next"></div>
                        <!-- Swiper prev/next buttons end -->

                        <!-- Swiper pagination -->
                        <div class="swiper-pagination" slot="pagination"></div>
                        <!-- Swiper pagination end -->
                    </swiper>

                    <!-- Swiper end -->
                </div>
                <div :class="[
                    'muc-list-item-recipe-infos',
                    { highlight: myUploadedCode.isWinner },
                ]">
                    <div class="text-start mb-2">Feltöltött adatok:</div>
                    <div class="text-start fw-bold">
                        {{
                            myUploadedCode.type === "AP_CODE" ||
                                myUploadedCode.type === "COUPON_CODE"
                                ? myUploadedCode.code
                                : myUploadedCode.type === "ONLINE"
                                    ? myUploadedCode.invoiceNumber
                                    : myUploadedCode.code
                        }}
                    </div>
                    <div class="d-flex flex-row">
                        <span class="fw-bold">
                            {{ myUploadedCode.timeOfPurchase }}
                        </span>
                        <span v-if="myUploadedCode.receiptNumber" class="ms-auto">{{ myUploadedCode.receiptNumber
                            }}</span>
                        <span v-if="myUploadedCode.navCode" class="ms-auto">{{
                            myUploadedCode.navCode
                            }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.my-uploaded-codes-list {
    row-gap: 2rem;

    .el-loading-spinner .path {
        stroke: #0f6f84 !important;
    }

    .muc-list-item {
        font-size: 12px;
        border-radius: 4px;
        border: 1px solid #0f6f84;
        height: 100%;
        background-color: var(--card-head-background-color);

        .muc-list-item-uploaded-date {
            color: var(--card-head-text-color);
            padding: 0.5rem;
        }

        .muc-list-item-promo-product-image {
            min-height: 200px;
            margin-top: auto;
            margin-bottom: auto;
            height: 100%;
            padding: 0.5rem;

            .swiper-container {
                display: flex;
                height: 100%;

                .swiper-wrapper {
                    min-height: 200px;

                    .swiper-slide {
                        display: flex;
                        height: auto !important;
                        flex-direction: column;
                        margin-left: auto;
                        margin-right: auto;

                        img {
                            max-width: 100%;
                            max-height: 100%;
                            // object-fit: none;
                            object-fit: contain;
                        }
                    }
                }
            }

            .swiper-pagination-bullet {
                background-color: transparent;
                border: 1px solid var(--card-swiper-pagination-border-color);
                opacity: 1;
            }

            .swiper-pagination-bullet-active {
                background-color: var(--card-swiper-pagination-background-color);
            }

            .swiper-button-next,
            .swiper-button-prev {
                color: var(--card-swiper-navigation-color) !important;

                &::after {
                    font-size: 28px;
                    font-weight: bold;
                }
            }

            .swiper-button-next.swiper-button-disabled,
            .swiper-button-prev.swiper-button-disabled {
                display: none !important;
            }
        }

        .muc-list-item-recipe-infos {
            color: var(--card-body-text-color);
            padding: 0.5rem;
            margin-top: auto;
            min-height: 70px;
            background-color: var(--card-body-background-color);

            &.highlight {
                color: var(--card-body-winner-text-color);
                background-color: var(--card-body-winner-background-color);
            }
        }
    }
}
</style>
