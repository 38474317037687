export enum ImageFormat {
    PNG = ".png",
    JPEG = ".jpeg",
    WEBP = ".webp",
}

/**
 * Add url params to given url
 *
 * @param url
 * @param params
 * @returns
 */
function addUrlParams(url: string, params: { [key: string]: string }) {
    const urlObj = new URL(url);
    Object.keys(params).forEach((key) => {
        urlObj.searchParams.append(key, params[key]);
    });
    return urlObj.toString();
}

export function useImageMediaUrl(
    imageFile: string,
    imageFormat: ImageFormat,
    width?: number,
    height?: number
): string {
    const fileUrl = `${useApiBaseUrl()}imagemanagerms/${imageFile}${imageFormat}`;
    const params: { [key: string]: string } = {};

    width ? (params.width = width.toString()) : undefined;
    height ? (params.height = height.toString()) : undefined;

    const updatedUrl = addUrlParams(fileUrl, params);

    return updatedUrl;
}
