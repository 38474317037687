<template>
    <section>
        <div id="winners" class="py-10" :style="backgroundStyle">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-6 offset-md-3">
                        <h2
                            class="text-center mb-5"
                            :style="sectionTitleStyle"
                            v-if="section.sectionTitleRaw"
                            v-html="section.sectionTitleRaw"
                        ></h2>

                        <div
                            class="description text-center mb-5"
                            :style="sectionParagraphStyle"
                            v-if="section.sectionParagraphRaw"
                            v-html="section.sectionParagraphRaw"
                        ></div>
                    </div>
                </div>

                <div class="row">
                    <!-- <div
                        class="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2 col-xxl-8 offset-xxl-2 mt-4 mb-6"
                    > -->
                    <div
                        class="col-12 col-md-8 offset-md-2 col-xl-6 offset-xl-3 col-xxl-8 offset-xxl-2 mt-4 mb-6"
                    >
                        <SwipeButtons
                            v-model="winnersType.selected"
                            :style="winnersListToggleButtonMergedStyle"
                            :buttons="winnersTypeToggleButton"
                        />
                    </div>
                </div>
                <WinnersList
                    :component="section.components"
                    :list="winnersList"
                />

                <!-- Main prize -->
                <div
                    v-if="winnerListIsEmpty"
                    class="d-flex justify-content-center align-items-center"
                >
                    <div
                        class="main-prize-winner no-draw fw-bold"
                        :style="sectionParagraphStyle"
                    >
                        Még nincs kihirdetve
                    </div>
                </div>
                <!-- Main prize end -->
            </div>
        </div>

        <!-- Prizific page builder tool -->
        <client-only>
            <PrizificPageBuilderTool v-if="editable" :section="section" />
        </client-only>
        <!-- Prizific page builder tool end -->
    </section>
</template>

<script setup lang="ts">
import { useWinnersStore } from "@/store/components/winnersList/winnersList";
import { EProperty } from "~~/enums/PrizificPageBuilder/EProperty";
import { getStyle } from "../../composables/styleHelper";
import { useCampaignDataStore } from "@/store/campaign/campaignData";

const winnersStore = useWinnersStore();

const campaignDataStore: any = useCampaignDataStore();

interface Props {
    section: any;
    editable: boolean;
}
onMounted(async () => {
    //await campaignDataStore.getCampaignData();
});

const winnersListComponent = computed(() => {
    return props.section?.components[0];
});

const winnersTypeToggleButton = computed(() => {
    if (!campaignDataStore.campaignPrizes) {
        return [];
    }

    return campaignDataStore.campaignPrizes.map((prize: any) => {
        let name = "-";
        let value = "";

        const runtimeConfig = useRuntimeConfig();

        if (
            campaignDataStore.campaignUUId ==
                runtimeConfig.ferfipromoCampaignUUId &&
            prize.type == "jackpot"
        ) {
            name = "Nyerőidőpontos";
            value = "main";
        } else if (prize.type == "jackpot") {
            if (
                campaignDataStore.campaignUUId ==
                    "cc601dc3-716a-4cb3-a994-14d3ec111761" ||
                campaignDataStore.campaignUUId ==
                    "a46bdb28-0c9b-463b-a48e-b2e78acd71b8"
            ) {
                name = "Extra nyeremény";
            } else {
                name =
                    winnersListComponent.value.mainPrizeTabText ||
                    "Főnyeremény";
            }
            // name = "Főnyeremény";
            value = "main";
        } else if (prize.type == "month") {
            name =
                winnersListComponent.value.weeklyPrizeTabText ||
                "Havi nyeremény";
            value = "monthly";
        } else if (prize.type == "week") {
            name =
                winnersListComponent.value.weeklyPrizeTabText ||
                "Heti nyeremény";
            value = "weekly";
        } else if (prize.type == "day") {
            name =
                winnersListComponent.value.dailyPrizeTabText ||
                "Napi nyeremény";
            value = "daily";
        } else if (prize.type == "extra") {
            name = "Extra Nyeremény";
            value = "extra";
        }

        return {
            name: name,
            value: value,
        };
    });
});

/**
 * Define props
 */
const props = defineProps<Props>();

const winnersType = ref({ selected: "" });

if (winnersTypeToggleButton.value.length) {
    winnersType.value.selected = winnersTypeToggleButton.value[0]?.value;
}

// Computed form and form items style
const backgroundStyle = computed(() => {
    return getStyle(props.section.style, "winnersBackground");
});
const sectionTitleStyle = computed(() => {
    return getStyle(props.section.style, "winnersTitle");
});
const sectionParagraphStyle = computed(() => {
    return getStyle(props.section.style, "winnersParagraph");
});
const winnersListToggleButtonStyle = computed(() => {
    return getStyle(
        props.section.components[0]?.style ?? [],
        "winnersListToggleButton"
    );
});
const winnersListToggleButtonActiveStyle = computed(() => {
    return getStyle(
        props.section.components[0]?.style ?? [],
        "winnersListToggleButtonActive"
    );
});

const winnersListToggleButtonMergedStyle = computed(() => {
    return {
        "--toggle-button-color":
            winnersListToggleButtonStyle.value[EProperty.COLOR],
        "--toggle-button-active-color":
            winnersListToggleButtonActiveStyle.value[EProperty.COLOR],
        "--toggle-button-active-background-color":
            winnersListToggleButtonActiveStyle.value[
                EProperty.BACKGROUND_COLOR
            ],
        "--toggle-button-border-color":
            winnersListToggleButtonStyle.value[EProperty.BORDER_COLOR],
        "--toggle-button-background-color":
            winnersListToggleButtonStyle.value[EProperty.BACKGROUND_COLOR],
        "--toggle-button-border-radius":
            winnersListToggleButtonStyle.value[EProperty.BORDER_RADIUS],
    };
});

const winnersList = computed(() => {
    if (winnersType.value.selected == "daily") {
        return winnersStore.winnersDailyList;
    } else if (winnersType.value.selected == "weekly") {
        return winnersStore.winnersWeeklyList;
    } else if (winnersType.value.selected == "monthly") {
        return winnersStore.winnersMonthlyList;
    } else if (winnersType.value.selected == "main") {
        return winnersStore.winnersMainList;
    }
});

const winnerListIsEmpty = computed(() => {
    try {
        if (winnersList.value != undefined && winnersList.value != null) {
            for (let periods of winnersList.value) {
                for (let periodName in periods) {
                    if (periods[periodName] && periods[periodName].length > 0) {
                        return false;
                    }
                }
            }
        }
    } catch (e) {
        return true;
    }

    return true;
});
</script>

<style lang="scss" scoped>
#winners {
    min-height: calc(100vh - 552px);
}

.main-prize-title {
    font-size: 1.5rem !important;

    p {
        margin: 0;
    }
}

.main-prize-winner {
    font-size: 1.5rem !important;

    &.no-draw {
        color: #c0c0c0 !important;
    }
}
</style>
