<template>
    <div
        ref="prizificPageBuilderAddSection"
        :class="[
            'prizific-page-builder-add-section',
            { opened: prizificPageBuilderAddSectionData.opened },
        ]"
    >
        <!-- Add section button -->
        <client-only>
            <el-tooltip
                content="Add new section"
                effect="prizific"
                placement="bottom"
                :disabled="prizificPageBuilderAddSectionData.show"
            >
                <div
                    :class="[
                        'prizific-page-builder-add-section__open',
                        { active: prizificPageBuilderAddSectionData.show },
                    ]"
                    @click="
                        prizificPageBuilderAddSectionData.show =
                            !prizificPageBuilderAddSectionData.show
                    "
                >
                    <span class="material-icons">add</span>
                </div>
            </el-tooltip>
        </client-only>
        <!-- Add section button end -->

        <Transition
            name="custom-classes-transition"
            enter-active-class="animate__animated animate__fadeIn animate__faster"
            leave-active-class="animate__animated animate__fadeOut animate__faster"
            v-on:before-enter="beforeEnter"
            v-on:after-leave="afterLeave"
        >
            <div
                v-if="prizificPageBuilderAddSectionData.show"
                class="prizific-page-builder-add-section__popup"
            >
                <div class="prizific-page-builder-add-section__title">
                    Insert new section:
                </div>

                <client-only>
                    <el-scrollbar
                        wrap-class="prizific-page-builder-add-section__scrollbar"
                        max-height="560px"
                    >
                        <div
                            class="prizific-page-builder-add-section__scrollbar__wrapper"
                        >
                            <PrizificPageBuilderAddSectionItem
                                title="Image text content"
                                :sectionTitle="sectionTitle"
                                :sectionType="EComponents.IMAGE_TEXT_CONTENT"
                                :available="true"
                                @clickBefore="insertNewSection"
                                @clickAfter="insertNewSection"
                            >
                                <PrizificPageBuilderImageTextContentSkeleton />
                            </PrizificPageBuilderAddSectionItem>

                            <PrizificPageBuilderAddSectionItem
                                title="User consenting"
                                :sectionTitle="sectionTitle"
                                :sectionType="EComponents.USER_CONSENTING"
                                :available="true"
                                @clickBefore="insertNewSection"
                                @clickAfter="insertNewSection"
                            >
                                <PrizificPageBuilderImageTextContentSkeleton />
                            </PrizificPageBuilderAddSectionItem>

                            <PrizificPageBuilderAddSectionItem
                                title="Prizes for Mogyi Minion"
                                :sectionTitle="sectionTitle"
                                :sectionType="EComponents.PRIZES_MINION"
                                :available="true"
                                @clickBefore="insertNewSection"
                                @clickAfter="insertNewSection"
                            >
                                <PrizificPageBuilderSwipeImageTextContentSkeleton />
                            </PrizificPageBuilderAddSectionItem>

                            <PrizificPageBuilderAddSectionItem
                                title="Vote"
                                :sectionTitle="sectionTitle"
                                :sectionType="EComponents.VOTE_DEFAULT"
                                :available="true"
                                @clickBefore="insertNewSection"
                                @clickAfter="insertNewSection"
                            >
                                <PrizificPageBuilderVoteSectionSkeleton />
                            </PrizificPageBuilderAddSectionItem>

                            <PrizificPageBuilderAddSectionItem
                                title="Prizes for Omnia"
                                :sectionTitle="sectionTitle"
                                :sectionType="EComponents.PRIZES_BY_TYPE"
                                :available="true"
                                @clickBefore="insertNewSection"
                                @clickAfter="insertNewSection"
                            >
                                <PrizificPageBuilderSwipeImageTextContentSkeleton />
                            </PrizificPageBuilderAddSectionItem>

                            <PrizificPageBuilderAddSectionItem
                                title="Prizes for Staropramen"
                                :sectionTitle="sectionTitle"
                                :sectionType="EComponents.PRIZES_GROUP_BY_TYPE"
                                :available="true"
                                @clickBefore="insertNewSection"
                                @clickAfter="insertNewSection"
                            >
                                <PrizificPageBuilderSwipeImageTextContentSkeleton />
                            </PrizificPageBuilderAddSectionItem>

                            <PrizificPageBuilderAddSectionItem
                                title="Vote for Szentkirályi"
                                :sectionTitle="sectionTitle"
                                :sectionType="EComponents.VOTE"
                                :available="true"
                                @clickBefore="insertNewSection"
                                @clickAfter="insertNewSection"
                            >
                                <PrizificPageBuilderVoteSectionSkeleton />
                            </PrizificPageBuilderAddSectionItem>

                            <PrizificPageBuilderAddSectionItem
                                title="Game Steps for Szentkirályi"
                                :sectionTitle="sectionTitle"
                                :sectionType="
                                    EComponents.GAME_STEPS_SZENTKIRALYI
                                "
                                :available="true"
                                @clickBefore="insertNewSection"
                                @clickAfter="insertNewSection"
                            >
                                <PrizificPageBuilderVoteSectionSkeleton />
                            </PrizificPageBuilderAddSectionItem>

                            <PrizificPageBuilderAddSectionItem
                                title="Discount coupons for Staropramen"
                                :sectionTitle="sectionTitle"
                                :sectionType="EComponents.DISCOUNT_COUPONS"
                                :available="true"
                                @clickBefore="insertNewSection"
                                @clickAfter="insertNewSection"
                            >
                                <PrizificPageBuilderVoteSectionSkeleton />
                            </PrizificPageBuilderAddSectionItem>

                            <PrizificPageBuilderAddSectionItem
                                title="Contact for Staropramen"
                                :sectionTitle="sectionTitle"
                                :sectionType="
                                    EComponents.CONTACT_WITH_PRIVACY_POLICY
                                "
                                :available="true"
                                @clickBefore="insertNewSection"
                                @clickAfter="insertNewSection"
                            >
                                <PrizificPageBuilderVoteSectionSkeleton />
                            </PrizificPageBuilderAddSectionItem>

                            <PrizificPageBuilderAddSectionItem
                                title="Contact with privacy policy"
                                :sectionTitle="sectionTitle"
                                :sectionType="
                                    EComponents.CONTACT_PRIVACY_POLICY
                                "
                                :available="true"
                                @clickBefore="insertNewSection"
                                @clickAfter="insertNewSection"
                            >
                                <PrizificPageBuilderVoteSectionSkeleton />
                            </PrizificPageBuilderAddSectionItem>

                            <PrizificPageBuilderAddSectionItem
                                title="Footer small"
                                :sectionTitle="sectionTitle"
                                :sectionType="EComponents.FOOTER_SMALL"
                                :available="true"
                                @clickBefore="insertNewSection"
                                @clickAfter="insertNewSection"
                            >
                                <PrizificPageBuilderVoteSectionSkeleton />
                            </PrizificPageBuilderAddSectionItem>

                            <PrizificPageBuilderAddSectionItem
                                title="Swipe image text content"
                                :sectionTitle="sectionTitle"
                                :sectionType="
                                    EComponents.SWIPE_IMAGE_TEXT_CONTENT
                                "
                                :available="false"
                                @clickBefore="insertNewSection"
                                @clickAfter="insertNewSection"
                            >
                                <PrizificPageBuilderSwipeImageTextContentSkeleton />
                            </PrizificPageBuilderAddSectionItem>
                        </div>
                    </el-scrollbar>
                </client-only>
            </div>
        </Transition>
    </div>
</template>

<script lang="ts" setup>
import { ElTooltip, ElScrollbar } from "element-plus";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { ENewSectionPosition } from "~~/enums/PrizificPageBuilder/ENewSectionPosition";
import { IComponent } from "~~/interfaces/PrizificPageBuilder/IComponent";
import { IPage } from "~~/interfaces/PrizificPageBuilder/IPage";
import { useSetupSectionStore } from "~~/store/sectionSetup/sectionSetupStore";

const sectionStore: any = useSetupSectionStore();

interface Props {
    page: IPage<EComponents>;
    section: IComponent<EComponents>;
    position: number;
}

/**
 * Define props
 */
const props = defineProps<Props>();

const prizificPageBuilderAddSection = ref(null);

const prizificPageBuilderAddSectionData = reactive({
    show: false,
    collapsedParams: [],
    collapsedComponents: [],
    opened: false,
});

onMounted(() => {
    onClickOutside(prizificPageBuilderAddSection, (event: any) => {
        closeIfClickOutside(event);
    });
});

/**
 * If click outside PPB Tool then close PPB Tool
 * We need to checking color-picker because its outer in PPB Tool wrapper
 */
function closeIfClickOutside(event: any) {
    const elColorRegExp = new RegExp("el-color");
    const elInputInnerRegExp = new RegExp("el-input__inner");

    if (
        !elColorRegExp.test(event.target.className) &&
        !elInputInnerRegExp.test(event.target.className) &&
        event.target.className !== ""
    ) {
        prizificPageBuilderAddSectionData.show = false;
    }
}

/**
 * Helper function to fade animation
 * We need for smooth animation
 */
function beforeEnter() {
    prizificPageBuilderAddSectionData.opened =
        !prizificPageBuilderAddSectionData.opened;
}

/**
 * Helper function to fade animation
 * We need for smooth animation
 */
function afterLeave() {
    prizificPageBuilderAddSectionData.opened =
        !prizificPageBuilderAddSectionData.opened;
}

/**
 * Save selected section to selected position
 */
function insertNewSection(
    position: ENewSectionPosition,
    sectionType: EComponents
) {
    let order: any = {};

    if (position === ENewSectionPosition.BEFORE) {
        order = {
            previousComponentId: null,
            nextComponentId: props.section.getId(),
        };
    } else if (position === ENewSectionPosition.AFTER) {
        order = {
            previousComponentId: props.section.getId(),
            nextComponentId: null,
        };
    }

    sectionStore.setupSection(sectionType, props.page.getType(), order);
}

/**
 * Return with section title
 */
const sectionTitle = computed(() => {
    return props.section?.title;
});
</script>
<style lang="scss" scoped>
.prizific-page-builder-add-section {
    position: absolute;
    top: calc(50% - 20px);
    z-index: 2;
    right: 88px;
    display: flex;
    min-width: 40px;
    max-width: 40px;
    border-radius: 50%;
    flex-direction: column;

    &.opened {
        border-radius: 0;
        min-width: 420px;
        max-width: 720px;
        z-index: 3;
    }

    &__open {
        display: flex;
        width: 40px;
        height: 40px;
        cursor: pointer;
        color: #6e6e73;
        user-select: none;
        margin-left: auto;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        background-color: #ffffffb3;
        backdrop-filter: saturate(50%) blur(8px);
        box-shadow: 2px 4px 12px rgb(0 0 0 / 14%);
        transition: color 0.2s ease-in-out, transform 0.25s ease-in-out;

        &.active {
            color: black;
            transform: rotate(135deg);
        }

        &:hover {
            color: black;
        }
    }

    &__popup {
        position: relative;
        padding: 30px 12px 30px 30px;
        margin-top: 10px;
        white-space: normal;
        border-radius: 18px;
        background-color: #ffffff;
        backdrop-filter: saturate(50%) blur(8px);
        box-shadow: 2px 4px 12px rgb(0 0 0 / 14%);

        &::before {
            position: absolute;
            content: "";
            right: 12px;
            top: -7px;
            border-style: solid;
            border-width: 0px 8px 8px 8px;
            border-color: transparent transparent #ffffff transparent;
        }
    }

    &__title {
        font-size: 20px;
        margin-bottom: 32px;
    }

    &__scrollbar {
        width: 450px;

        &__wrapper {
            padding-right: 20px;
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.15s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
